define('ownersup-client/progress/reports/index/route', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    currentGroup: Ember.inject.service(),

    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      params.group_membership_id = [this.get('currentGroup.activeGroupMembershipId')];
      params.page_size = 50;
      params.perPageParam = 'page_size';
      params.totalPagesParam = 'meta.pagination.total_pages';

      if (Ember.isEmpty(params.type)) {
        delete params.type;
      }
      if (Ember.isEmpty(params.category_id)) {
        delete params.category_id;
      }

      return this.infinityModel('report-activity', params);
    },

    queryParams: {
      type: { refreshModel: true },
      category_id: { refreshModel: true }
    }
  });
});