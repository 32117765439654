define('ownersup-client/components/comment-count/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['comment-count', 'with-icon'],
    inflectedCount: Ember.computed('count', function () {
      var initCount = this.get('count');
      var count = initCount === 0 ? 'No' : initCount;
      var description = initCount === 1 ? 'Comment' : 'Comments';

      return count + ' ' + description;
    })
  });
});