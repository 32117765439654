define('ownersup-client/components/empty-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['data-test-id'],
    attributeBindings: ['data-test-id'],


    tagName: 'p',

    isLoading: Ember.computed('model.isLoading', function () {
      return !!this.get('model') && this.get('model.isLoading');
    })
  });
});