define('ownersup-client/mixins/timestampable', ['exports', 'ember-data', 'ember-moment/computeds/format', 'ember-moment/computeds/from-now'], function (exports, _emberData, _format, _fromNow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = Ember.Mixin.create({
    createdAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    createdAgo: (0, _fromNow.default)('createdAt', true),
    createdDate: (0, _format.default)('createdAt', 'MM/DD/YYYY'),

    updatedAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    updatedAgo: (0, _fromNow.default)('updatedAt', true),
    updatedDate: (0, _format.default)('updatedAt', 'MM/DD/YYYY')
  });
});