define('ownersup-client/mixins/paginated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      }
    },

    afterModel: function afterModel() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});