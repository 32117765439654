define('ownersup-client/group/index/route', ['exports', 'ownersup-client/mixins/reset-scroll'], function (exports, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, {
    model: function model() {
      return Ember.RSVP.hash({
        group: this.modelFor('group').group,
        groupMemberships: this.modelFor('group').groupMemberships
      });
    },
    afterModel: function afterModel() {
      this.transitionTo('group.reports.index');
    },


    queryParams: {
      page: { refreshModel: true },
      page_size: { refreshModel: true }
    }
  });
});