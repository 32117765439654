define('ownersup-client/progress/tallyups/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentGroup: Ember.inject.service(),

    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var groupMembership = this.get('currentGroup.activeGroupMembership');

      params.group_membership_id = [groupMembership.id];
      params.from = (0, _moment.default)().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
      params.page_size = 300;

      return this.get('store').query('tallyup', params);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.get('tallyUpStatsTask').perform();
    }
  });
});