define('ownersup-client/components/tally-up/component', ['exports', 'moment', 'ownersup-client/mixins/component-attribute-types'], function (exports, _moment, _componentAttributeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentAttributeTypes.default, {
    classNames: ['card', 'card-tally-up', 'tally-up'],
    classNameBindings: ['hasMonthlyGoal::empty-monthly-goal', 'showEditGoalForm'],
    currentGroup: Ember.inject.service(),
    groupMembership: Ember.computed.readOnly('currentGroup.activeGroupMembership'),

    attrTypes: {
      save: true,
      create: true
    },

    date: new Date(),
    showEditGoalForm: false,
    showNewTallyUpForm: false,
    hasMonthlyGoal: Ember.computed.bool('monthlyGoalAmount'),
    notEmpty: Ember.computed.or('showEditGoalForm', 'showNewTallyUpForm', 'hasMonthlyGoal'),
    showDefault: Ember.computed('hasMonthlyGoal', 'showEditGoalForm', 'showNewTallyUpForm', function () {
      return !(this.get('showEditGoalForm') || this.get('showNewTallyUpForm')) && this.get('hasMonthlyGoal');
    }),

    monthlyGoalAmount: Ember.computed.readOnly('groupMembership.monthlyGoalAmount'),
    currentMonthTallyups: Ember.computed.filter('groupMembership.tallyups', function (tallyup) {
      return (0, _moment.default)(tallyup.get('createdAt')).isSame(new Date(), 'month');
    }),
    amountsList: Ember.computed.mapBy('currentMonthTallyups', 'amount'),
    currentRevenue: Ember.computed.sum('amountsList'),
    monthlyGoalRemainder: Ember.computed('currentRevenue', 'monthlyGoalAmount', function () {
      return this.get('monthlyGoalAmount') - this.get('currentRevenue');
    }),

    actions: {
      toggleEditGoalForm: function toggleEditGoalForm() {
        this.toggleProperty('showEditGoalForm');
      },
      saveMonthlyGoal: function saveMonthlyGoal() {
        this.sendAction('save', this.get('groupMembership'), false, 'Monthly goal updated!');
        this.toggleProperty('showEditGoalForm');
      },
      newTallyUp: function newTallyUp() {
        this.toggleProperty('showNewTallyUpForm');
      },
      saveTallyUp: function saveTallyUp() {
        var amount = parseInt(this.get('tallyupAmount'));
        if (!amount) {
          return false;
        }

        var groupMembership = this.get('groupMembership');
        var tallyup = this.create('tallyup', { groupMembership: groupMembership, amount: amount });

        this.sendAction('save', tallyup, false, 'TallyUp successfully saved');
        this.toggleProperty('showNewTallyUpForm');
        this.set('tallyupAmount', null);
      }
    }
  });
});