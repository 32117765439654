define('ownersup-client/components/calendar-week/component', ['exports', 'ember-moment/computeds/format', 'moment'], function (exports, _format, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['calendar', 'calendar-week'],
    weekStart: Ember.computed('activeDate', function () {
      var date = this.get('activeDate');
      return (0, _moment.default)(date, 'YYYY-MM-DD').day(0).toDate();
    }),
    month: (0, _format.default)('activeDate', 'MMMM'),
    weekDays: Ember.computed('weekStart', function () {
      var start = this.get('weekStart');
      var dates = [start];
      for (var i = 1; i < 7; i++) {
        dates.push((0, _moment.default)(start).add(i, 'days').toDate());
      }
      return dates;
    }),

    actions: {
      setActiveDate: function setActiveDate(date) {
        this.sendAction('action', date);
      }
    }
  });
});