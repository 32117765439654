define('ownersup-client/mixins/group-membershipable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Ember.Mixin.create({
    groupMembership: belongsTo('groupMembership', { async: true }),
    user: Ember.computed.reads('groupMembership.user'),
    group: Ember.computed.reads('groupMembership.group'),
    isOwner: attr('boolean', { defaultValue: false })
  });
});