define('ownersup-client/messages/new/route', ['exports', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _groupMembershipContentRoute, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_groupMembershipContentRoute.default, _resetScroll.default, {
    model: function model() {
      return this.get('store').createRecord('message');
    },
    afterModel: function afterModel(model) {
      this._setGroupMembership(model);
    }
  });
});