define('ownersup-client/components/modal-base/component', ['exports', 'ownersup-client/mixins/component-name'], function (exports, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentName.default, {
    name: 'modal',
    flashMessages: Ember.inject.service(),

    _save: function _save(model, message) {
      var _this = this;

      var flashMessages = Ember.get(this, 'flashMessages');
      model.save().then(function () {
        flashMessages.success(message);
        _this.close();
      }, function (err) {
        _this.set('errors', err.errors);
      });
    }
  });
});