define('ownersup-client/mixins/trackable-record', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    trackModelEvent: function trackModelEvent(model) {
      var customAction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var action = customAction || (model.get('isNew') ? 'Created' : 'Updated');
      var name = model.constructor.modelName;

      // One off changes
      if (name === 'group-membership') {
        if (Object.keys(model.changedAttributes()).includes('monthlyGoalAmount')) {
          name = 'monthly goal';
        }
      }

      Ember.get(this, 'metrics').trackEvent({
        event: action + ' ' + name,
        id: model.get('id')
      });
    }
  });
});