define('ownersup-client/admin/groups/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('store').createRecord('group', params);
    }

    // afterModel(group) {
    //   return this.get('store').query('category', { ids: [1, 2, 3, 4, 5] }).then((results) => {
    //     results.forEach((category) => {
    //       return this.get('store').createRecord('group-category', { group, category });
    //     });
    //   });
    // }

  });
});