define('ownersup-client/components/category-help/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['category-help-component'],
    showHelp: false,

    actions: {
      toggleHelp: function toggleHelp() {
        this.toggleProperty('showHelp');
      }
    }
  });
});