define('ownersup-client/facilitator-dashboard/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentGroup: Ember.inject.service(),
    model: function model() {
      return this.get('currentGroup.model');
    },

    currentGroupObserver: Ember.observer('currentGroup.id', function () {
      this.refresh();
    })
  });
});