define('ownersup-client/report/model', ['exports', 'ember-data', 'ownersup-client/commentable/model', 'ownersup-client/mixins/group-membershipable'], function (exports, _emberData, _model, _groupMembershipable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _model.default.extend(_groupMembershipable.default, {
    reportActivitiesCount: attr('number'),
    reportActivities: hasMany('report-activity', { async: true }),

    status: attr('string', { defaultValue: 'draft' }),
    isDraft: Ember.computed.equal('status', 'draft'),
    isPublished: Ember.computed.equal('status', 'published'),

    activitySort: ['createdAt:asc', 'updatedAt:asc'],
    accomplishments: Ember.computed.filterBy('reportActivities', 'type', 'Accomplishment'),
    lessons: Ember.computed.filterBy('reportActivities', 'type', 'Lesson'),
    journals: Ember.computed.filterBy('reportActivities', 'type', 'Journal'),

    reportActivitiesSorted: Ember.computed.sort('reportActivities', 'activitySort'),
    accomplishmentsSorted: Ember.computed.sort('accomplishments', 'activitySort'),
    lessonsSorted: Ember.computed.sort('lessons', 'activitySort'),

    isEmpty: Ember.computed('reportActivities.@each.notEmpty', function () {
      return !this.get('reportActivities').isAny('notEmpty');
    }),
    isNewAndEmpty: Ember.computed.and('isNew', 'isEmpty'),

    ready: function ready() {
      this._addDefaultActivity();
    },
    _addDefaultActivity: function _addDefaultActivity() {
      var _this = this;

      var createdAt = Ember.get(this, 'createdAt');

      Ember.get(this, 'reportActivities').then(function (reportActivities) {
        if (Ember.get(_this, 'journals.length') === 0) {
          reportActivities.createRecord({ type: 'Journal', createdAt: createdAt });
        }
        if (Ember.get(_this, 'accomplishments.length') === 0) {
          reportActivities.createRecord({ type: 'Accomplishment', createdAt: createdAt });
        }
        if (Ember.get(_this, 'lessons.length') === 0) {
          reportActivities.createRecord({ type: 'Lesson', createdAt: createdAt });
        }
      });
    }
  });
});