define('ownersup-client/group/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable'], function (exports, _emberData, _timestampable, _authorizable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_timestampable.default, _authorizable.default, {
    name: attr('string'),
    groupMembershipsCount: attr('number'),

    groupMemberships: hasMany('groupMemberships', { async: true }),
    groupMembershipIds: Ember.computed.mapBy('groupMemberships', 'id'),
    liveGroupMemberships: Ember.computed('groupMemberships.@each.isDeleted', function () {
      return this.get('groupMemberships').filterBy('isDeleted', false);
    }),
    activeGroupMemberships: Ember.computed.filterBy('liveGroupMemberships', 'isActive'),
    invitedGroupMemberships: Ember.computed.filterBy('liveGroupMemberships', 'isInvited'),
    members: Ember.computed.filterBy('liveGroupMemberships', 'role', 'member'),
    activeMembers: Ember.computed.filterBy('members', 'isActive'),

    users: Ember.computed.mapBy('groupMemberships', 'user'),

    groupCategories: hasMany('group-categories', { async: false }),
    categories: Ember.computed.mapBy('groupCategories', 'category')
  });
});