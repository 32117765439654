define('ownersup-client/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ownersup-client/mixins/rollback-record', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _applicationRouteMixin, _rollbackRecord, _flashMessageable, _trackableRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var testing = Ember.testing;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _flashMessageable.default, _rollbackRecord.default, _trackableRecord.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentGroup: Ember.inject.service(),
    metrics: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.get('session.isAuthenticated')) {
        return this._hydrateCurrentUser();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (this.get('session.isAuthenticated')) {
        controller._verifyUserStatus();
      }
    },


    actions: {
      deleteRecord: function deleteRecord(record) {
        var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'index';
        var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Successfully deleted';

        this._deleteRecord(record, path, message);
      },
      rollbackRecord: function rollbackRecord(record) {
        var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'index';

        this._rollbackRecord(record, path);
      },


      // IDEA: Swap arguemtns to record, message, path, arguments
      // This way we can redirect to a specific model
      saveRecord: function saveRecord(record) {
        var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'index';
        var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'Successfully saved!';

        this._saveRecord(record, path, message);
      },
      setCurrentGroupMembership: function setCurrentGroupMembership(groupMembership) {
        var session = this.get('session');
        session.set('data.activeGroupMembershipId', groupMembership.get('id'));
      },
      newComment: function newComment(comment) {
        this.get('store').createRecord('comment', comment);
      },
      showModal: function showModal(modalDialogName, modalContext) {
        this._showModal(modalDialogName, modalContext);
      }
    },

    identifyUser: function identifyUser() {
      if (this.get('session.isAuthenticated')) {
        var user = this.get('currentUser.user');
        var userProperties = user.getProperties('id', 'email', 'companyName', 'role', 'stripePlanId', 'isCreditCardOnFile', 'isTrialing');
        userProperties.distinctId = user.get('id');
        userProperties.name = user.get('fullName');

        Ember.set(this, 'metrics.context', userProperties);
        // TEMP: Don't use metrics service, as it doesnt support
        // passing the extra options
        // get(this, 'metrics').identify(userProperties);

        window.profitwell && window.profitwell('user_email', user.get('email'));

        window.analytics && window.analytics.identify(user.get('id'), userProperties, {
          Intercom: {
            user_hash: user.get('intercomUserHash')
          }
        });

        if (typeof Bugsnag !== 'undefined') {
          Bugsnag.user = userProperties;
        }
      }
    },
    _hydrateCurrentUser: function _hydrateCurrentUser() {
      var _this = this;

      return this.get('currentUser.user').then(function () {
        if (!_this.get('session.data.activeGroupMembershipId')) {
          var groupMembership = _this.get('session.data.authenticated.group_membership_ids.firstObject');
          _this.set('session.data.activeGroupMembershipId', groupMembership);
        }

        return _this.get('currentGroup.group').then(function () {
          _this.identifyUser();
        });
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      Ember.get(this, 'flashMessages').success('Welcome! You are now logged in.');

      var attemptedTransition = this.get('session.attemptedTransition');

      this._hydrateCurrentUser().then(function () {
        if (attemptedTransition) {
          attemptedTransition.retry();
          _this2.set('session.attemptedTransition', null);
        } else {
          _this2.transitionTo('index');
        }
      });
    },
    sessionInvalidated: function sessionInvalidated() {
      this.get('session.store').clear();
      if (!testing) {
        window.location.replace('/');
      }
    },
    _deleteRecord: function _deleteRecord(record, path, message) {
      var _this3 = this;

      record.destroyRecord().then(function () {
        _this3.trackModelEvent(record, 'Deleted');

        if (path) {
          _this3.transitionTo(path);
        }

        _this3._displaySuccessMessage(message);
      }).catch(function (err) {
        _this3._displayErrors(err);
      });
    },
    _showModal: function _showModal(modalDialogName, modalContext) {
      this.controllerFor('application').send('showModal', modalDialogName, modalContext);
    },
    _saveRecord: function _saveRecord(record, path, message) {
      var _this4 = this;

      this.trackModelEvent(record);

      record.save().then(function () {
        if (path) {
          if (path === _this4.controller.currentPath) {
            _this4.refresh();
          } else {
            _this4.transitionTo(path);
          }
        }
        _this4._displaySuccessMessage(message);
      }, function (err) {
        _this4._displayErrors(err);
      });
    }
  });
});