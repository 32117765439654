define('ownersup-client/components/user-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        var user = this.get('user');

        this.sendAction('action', user, 'account.profile', 'Profile successfully saved');
      }
    }
  });
});