define('ownersup-client/mixins/confirmable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isConfirming: false,
    confirmationMessage: 'Are you sure?',

    actions: {
      confirm: function confirm() {
        this._confirm();
      },
      denied: function denied() {
        this._deny();
      }
    },

    _confirm: function _confirm() {
      this.set('isConfirming', true);
    },
    _deny: function _deny() {
      this.set('isConfirming', false);
    }
  });
});