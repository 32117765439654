define('ownersup-client/helpers/number-to-k', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberToK = numberToK;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function lastDigit(number) {
    return number.charAt(number.length - 1);
  }

  function trimNumber(number) {
    number = number.slice(0, -2); // 12,300 -> 123

    var trimmerNumber = void 0;

    if (lastDigit(number) === '0') {
      trimmerNumber = number.slice(0, -1); // 120 -> 12
    } else {
      trimmerNumber = [number.slice(0, -1), '.', number.slice(-1)].join(''); // 123 -> 12.3
    }

    return trimmerNumber;
  }

  function appendK(number, html) {
    if (html) {
      return number + '<span class="k">K</span>';
    } else {
      return number + 'K';
    }
  }

  function prepend$(number, html) {
    if (html) {
      return ('<span class="symbol">$</span>' + number).htmlSafe();
    } else {
      return '$' + number;
    }
  }

  function numberToK(amount) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { html: true },
        html = _ref.html;

    if (Ember.isArray(amount)) {
      var _amount = amount;

      var _amount2 = _slicedToArray(_amount, 1);

      amount = _amount2[0];
    }
    if (!amount) {
      amount = 0;
    }
    var number = '' + parseInt(amount);

    if (number.length > 3) {
      number = trimNumber(number);
      number = appendK(number, html);
    }

    return prepend$(number, html);
  }

  exports.default = Ember.Helper.helper(numberToK);
});