define('ownersup-client/helpers/badge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.badge = badge;
  var EmberS = Ember.String;
  function badge(content) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        type = _ref.type;

    var classNames = ['badge', ('badge-' + (type ? type : content)).toLowerCase()];

    var html = '<span class="' + classNames.join(' ') + '">' + content + '</span>';

    return EmberS.htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(badge);
});