define('ownersup-client/mixins/group-membership-content-route', ['exports', 'ownersup-client/mixins/rollback-record'], function (exports, _rollbackRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_rollbackRecord.default, {
    currentGroup: Ember.inject.service(),
    session: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('editedModel', 'model');
    },


    actions: {
      willTransition: function willTransition(transition) {
        var model = this._getModel(transition);

        if (!model) {
          return true;
        }

        if (this._isNewButUnmodified(model)) {
          this._rollbackRecord(model);
          return false;
        }

        if (model.get('hasDirtyAttributes') && Object.keys(model.changedAttributes()) !== 0 && !confirm('You have unsaved modifications. Are you OK with loosing them?')) {
          transition.abort();
        }
      }
    },

    _getModel: function _getModel(transition) {
      if (transition && transition.data.model) {
        return transition.data.model;
      }

      var modelKey = this.controller.get('editedModel');
      return this.controller.get(modelKey);
    },
    _isNewButUnmodified: function _isNewButUnmodified(model) {
      return model.get('isNew') && Object.keys(model.changedAttributes()).length === 0;
    },
    _getRouteGroupGroupMemberhipIds: function _getRouteGroupGroupMemberhipIds() {
      return this.get('currentGroup.groupMembershipIds');
    },
    _setGroupMembership: function _setGroupMembership(model) {
      var groupMembership = this.get('currentGroup.activeGroupMembership');

      return model.set('groupMembership', groupMembership);
    }
  });
});