define('ownersup-client/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentGroup: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('currentGroup.isCurrentUserGroupFacilitator')) {
        this.transitionTo('facilitator-dashboard');
      } else {
        this.transitionTo('dashboard');
      }
    }
  });
});