define('ownersup-client/users/password/new/route', ['exports', 'ownersup-client/utils/format-error-json'], function (exports, _formatErrorJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),

    actions: {
      requestReset: function requestReset() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var email = this.controller.get('email');
        var userAdapter = this.get('store').adapterFor('user');

        return userAdapter.requestNewPassword(email).then(function () {
          flashMessages.success('An email has been sent with instructions to reset your password');
        }, function (error) {
          flashMessages.danger((0, _formatErrorJson.default)(error));
        });
      }
    }
  });
});