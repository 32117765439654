define('ownersup-client/subscription/model', ['exports', 'ember-data', 'ownersup-client/config/environment', 'moment'], function (exports, _emberData, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    user: belongsTo('user', { async: false }),

    start: attr('date'), // '2015-09-21T22:09:00.000Z',
    stripe_id: attr('string'), // 'sub_71foSWZ9ntxDJv',
    status: attr('string'), // 'active',
    customer: attr('string'), // 'cus_71d3VxRtE1mR2x',
    planId: attr('string'), // 'ownersup_weekly',
    cancelAtPeriodEnd: attr('boolean'), // false,
    currentPeriodStart: attr('date'), // '2015-09-21T22:09:00.000Z',
    currentPeriodEnd: attr('date'), // '2015-10-21T22:09:00.000Z',
    endedAt: attr('date'), // null,
    trialStart: attr('date'), // null,
    trialEnd: attr('date'), // null,
    canceledAt: attr('date'), // null,
    discount: attr('string'), // null,
    createdAt: attr('date'), // '2015-09-21T22:09:14.676Z',
    updatedAt: attr('date'), // '2015-09-21T22:09:14.676Z'

    isFree: Ember.computed('planId', function () {
      var planId = this.get('planId');

      return _environment.default.APP.stripePlans[planId].amount === 0;
    }),

    inTrial: Ember.computed('trialStart', 'trialEnd', function () {
      var _getProperties = this.getProperties('trialStart', 'trialEnd'),
          trialStart = _getProperties.trialStart,
          trialEnd = _getProperties.trialEnd;

      return (0, _moment.default)().isBetween(trialStart, trialEnd);
    }),

    isTrialEndingSoon: Ember.computed('trialEnd', function () {
      var trialEnd = (0, _moment.default)(this.get('trialEnd'));

      if (!trialEnd) {
        return false;
      }

      var now = (0, _moment.default)();
      var soon = (0, _moment.default)().add(2, 'weeks');

      return trialEnd.isBetween(now, soon);
    }),

    hasTrialExpired: Ember.computed('trialEnd', function () {
      var trialEnd = (0, _moment.default)(this.get('trialEnd'));
      var now = (0, _moment.default)();

      return trialEnd.isBefore(now, 'day');
    })
  });
});