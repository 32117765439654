define('ownersup-client/admin/users/index/route', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    queryParams: {
      confirmed: { refreshModel: true },
      role: { refreshModel: true }
    },
    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      params.page_size = 10;
      params.perPageParam = 'page_size';
      params.totalPagesParam = 'meta.pagination.total_pages';
      return this.infinityModel('user', params);
    }
  });
});