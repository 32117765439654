define('ownersup-client/dashboard/route', ['exports', 'moment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _moment, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentGroup: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        goals: this.get('store').query('goal', {
          group_membership_id: [this.get('currentGroup.activeGroupMembershipId')],
          status: 'active'
        }),
        reports: this.get('store').query('report', {
          group_membership_id: this.get('currentGroup.groupMembershipIds')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      Ember.RSVP.hash({
        messages: this.get('store').query('message', {
          group_membership_id: this.get('currentGroup.groupMembershipIds')
        }),
        tallyups: this.get('store').query('tallyup', {
          from: (0, _moment.default)().date(1).format('YYYY-MM-DD'),
          group_membership_id: [this.get('currentGroup.activeGroupMembershipId')]
        })
      }).then(function (results) {
        controller.setProperties({
          'model.messages': results.messages
        });
      });
    },


    actions: {
      setActiveDate: function setActiveDate(date) {
        this.controller.set('activeDate', date);
      }
    }
  });
});