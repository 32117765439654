define('ownersup-client/components/flash-trial-ending/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['flash-trial-ending'],

    actions: {
      processStripeToken: function processStripeToken(token) {
        return this.processStripeToken(token);
      }
    }
  });
});