define('ownersup-client/messages/message/route', ['exports', 'ownersup-client/mixins/group-membership-content-route'], function (exports, _groupMembershipContentRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_groupMembershipContentRoute.default, {
    model: function model(params) {
      return this.get('store').findRecord('message', params.message_id);
    }
  });
});