define('ownersup-client/components/goal-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    goal: Ember.computed.alias('commentable'),
    shouldReflect: false,
    noReflection: Ember.computed.lt('goal.reflection.length', 5),

    actions: {
      complete: function complete(result) {
        var goal = Ember.get(this, 'goal');

        goal.setProperties({
          result: result,
          'status': 'completed',
          'completedAt': Date.now()
        });

        this.toggleProperty('shouldReflect');
      },
      submit: function submit() {
        var goal = Ember.get(this, 'goal');
        var flashMessages = Ember.get(this, 'flashMessages');

        if (Ember.isBlank(Ember.get(goal, 'reflection'))) {
          flashMessages.danger('Take some time to reflect on your goal before you submit it!');
          return false;
        }

        this.toggleProperty('shouldReflect');

        this.sendAction('submit', goal, 'progress.goals');
      },
      cancel: function cancel() {
        this.toggleProperty('shouldReflect');

        var goal = Ember.get(this, 'goal');
        this.sendAction('cancel', goal, false);
      }
    }
  });
});