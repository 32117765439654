define('ownersup-client/groups/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _transition$params$gr = transition.params.groups.wildcard.split('/'),
          _transition$params$gr2 = _slicedToArray(_transition$params$gr, 3),
          model = _transition$params$gr2[1],
          modelId = _transition$params$gr2[2];

      var modelSingular = model.substring(0, model.length - 1);

      return this.transitionTo('group.' + model + '.' + modelSingular, modelId);
    }
  });
});