define('ownersup-client/components/chart-base/component', ['exports', 'ownersup-client/mixins/spinnerable', 'ownersup-client/mixins/component-name'], function (exports, _spinnerable, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spinnerable.default, _componentName.default, {
    name: 'progress-chart',

    isEmpty: Ember.computed('model', function () {
      return this.get('model.length') < 1;
    }),

    data: false,
    axis: false,
    grid: false,
    color: {
      pattern: ['#F06352', // Brand primary
      '#98CA76', // Green
      '#4ABFB6', // Turquoise
      '#759D59', // Dark Green
      '#F88F48', // Orange
      '#458ECC' // Blue
      ]
    }
  });
});