define('ownersup-client/components/modals/modal-move-group-membership/component', ['exports', 'ownersup-client/components/modal-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.ids = [];
    },

    store: Ember.inject.service(),
    groupMembership: Ember.computed.oneWay('modalContext.groupMembership'),
    results: Ember.computed(function () {
      return this.get('store').peekAll('group');
    }),

    filteredResults: Ember.computed('results.@each.id', 'ids', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'ids', 'results'),
          ids = _EmberGetProperties.ids,
          results = _EmberGetProperties.results;

      return results.filter(function (group) {
        return ids.includes(parseInt(group.id));
      });
    }),

    actions: {
      selectResult: function selectResult(group) {
        var groupMembership = Ember.get(this, 'groupMembership');

        Ember.set(groupMembership, 'group', group);

        this._save(groupMembership, 'Success! The group membership has been moved');
      },
      handleResults: function handleResults(results) {
        var groups = results.groups;

        Ember.get(this, 'store').pushPayload({ groups: groups });
        Ember.set(this, 'ids', groups.mapBy('id'));
      }
    }
  });
});