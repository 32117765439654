define('ownersup-client/tallyup/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/group-membershipable'], function (exports, _emberData, _timestampable, _authorizable, _groupMembershipable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_groupMembershipable.default, _authorizable.default, _timestampable.default, {
    amount: attr('number'),
    groupMembership: belongsTo('group-membership', { inverse: 'tallyups' }),
    monthlyGoalAmount: attr('number'),

    isEditing: attr('boolean', { defaultValue: false }),

    didUpdate: function didUpdate() {
      this.set('isEditing', false);
    }
  });
});