define('ownersup-client/components/comment-list-item/component', ['exports', 'ownersup-client/mixins/confirmable', 'ownersup-client/mixins/component-attribute-types'], function (exports, _confirmable, _componentAttributeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_confirmable.default, _componentAttributeTypes.default, {
    currentUser: Ember.inject.service('current-user'),

    classNames: ['comment-list-item'],
    classNameBindings: ['isConfirming'],

    attrTypes: {
      delete: true
    },

    actions: {
      edit: function edit(comment) {
        comment.set('isEditing', true);
        this.sendAction('isEditing', true);
      },
      delete: function _delete(comment) {
        this.sendAction('delete', comment);
      }
    }
  });
});