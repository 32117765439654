define('ownersup-client/authenticators/devise', ['exports', 'ember-simple-auth/authenticators/devise', 'ownersup-client/config/environment'], function (exports, _devise, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    resourceName: 'api_v1_user',
    serverTokenEndpoint: _environment.default.APP.API_HOST + '/v1/users/sign_in'
  });
});