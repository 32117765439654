define('ownersup-client/components/goal-list/component', ['exports', 'ownersup-client/mixins/component-attribute-types', 'ownersup-client/mixins/component-name'], function (exports, _componentAttributeTypes, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentAttributeTypes.default, _componentName.default, {
    name: 'goal-list',
    classNames: ['list-group', 'goal-list'],

    goals: []
  });
});