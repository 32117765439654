define('ownersup-client/helpers/format-duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDuration = formatDuration;
  function formatDuration(params /*, hash*/) {
    var minutes = '' + parseInt(params);
    if (minutes > 60) {
      var minPortion = minutes % 60;
      var hourPortion = Math.floor(minutes / 60);

      if (minPortion === 0) {
        return hourPortion + ' hours';
      } else {
        return hourPortion + 'h ' + minPortion + 'm';
      }
    } else {
      return minutes + ' mins';
    }
  }

  exports.default = Ember.Helper.helper(formatDuration);
});