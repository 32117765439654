define('ownersup-client/components/pop-over/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ESC = 27;

  exports.default = Ember.Component.extend({
    classNames: ['popover'],
    classNameBindings: ['placement'],
    attributeBindings: ['role'],

    isVisible: false,
    placement: 'bottom',
    role: 'tooltip',

    init: function init() {
      this._super.apply(this, arguments);
      this._closeOnClickOut = this._closeOnClickOut.bind(this);
      this._closeOnEsc = this._closeOnEsc.bind(this);
    },
    didRender: function didRender() {
      var _this = this;

      Ember.run.next(function () {
        var method = _this.get('isVisible') && !_this.get('isDestroyed') ? 'on' : 'off';
        var jqueryWindow = Ember.$(window);
        jqueryWindow[method]('click', _this._closeOnClickOut);
        jqueryWindow[method]('keyup', _this._closeOnEsc);
      });
    },
    _close: function _close() {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set('isVisible', false);
      this.sendAction('close');
    },
    _closeOnClickOut: function _closeOnClickOut(e) {
      var element = this.get('element');
      if (element) {
        var clickIsInside = element === e.target || Ember.$.contains(element, e.target);
        var clickIsLink = Ember.$(e.target).prop('tagName') === 'A';

        if (!clickIsInside || clickIsLink) {
          this._close();
        }
      }
    },
    _closeOnEsc: function _closeOnEsc(e) {
      if (e.keyCode === ESC) {
        this._close();
      }
    }
  });
});