define('ownersup-client/components/message-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        var message = this.get('message');

        this.sendAction('save', message);
      },
      cancel: function cancel() {
        var message = this.get('message');

        this.sendAction('cancel', message);
      },
      delete: function _delete() {
        var message = this.get('message');

        this.sendAction('delete', message);
      }
    }
  });
});