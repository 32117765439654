define('ownersup-client/components/markdown-textarea/component', ['exports', 'ownersup-client/mixins/component-name'], function (exports, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentName.default, {
    name: 'markdown-textarea',
    classNames: ['form-group'],
    classNameBindings: ['labelClass', 'previewVisible'],
    previewVisible: false,
    previewNotVisible: Ember.computed.not('previewVisible'),

    rows: 2,

    labelClass: Ember.computed('label', function () {
      if (this.get('class')) {
        return this.get('class').dasherize();
      }
      if (this.get('label')) {
        return this.get('label').dasherize();
      }
    }),

    actions: {
      togglePreview: function togglePreview() {
        var visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        Ember.set(this, 'previewVisible', visible);
      }
    }
  });
});