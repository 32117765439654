define('ownersup-client/progress/goals/index/route', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    currentGroup: Ember.inject.service(),

    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      params.group_membership_id = [this.get('currentGroup.activeGroupMembershipId')];
      params.page_size = 10;
      params.perPageParam = 'page_size';
      params.totalPagesParam = 'meta.pagination.total_pages';

      return this.infinityModel('goal', params);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('listSort', ['status:asc', 'dueDate:asc', 'createdAt:desc']);
    }
  });
});