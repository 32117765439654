define('ownersup-client/admin/users/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['confirmed', 'role'],
    confirmed: true,
    role: 'user'
  });
});