define('ownersup-client/components/goal-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    currentGroup: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    classNames: ['goal-form'],
    actions: {
      submit: function submit() {
        var goal = this.get('goal');

        if (this._validGoal()) {
          this.sendAction('save', goal, 'progress.goals');
        }
      },
      submitAndNew: function submitAndNew() {
        var goal = this.get('goal');

        if (this._validGoal()) {
          this.sendAction('save', goal, 'goals.new');
        }
      },
      cancel: function cancel() {
        var goal = this.get('goal');

        this.sendAction('cancel', goal, 'progress.goals');
      },
      delete: function _delete() {
        var goal = this.get('goal');

        this.sendAction('delete', goal, 'progress.goals');
      }
    },

    _validGoal: function _validGoal() {
      var flashMessages = Ember.get(this, 'flashMessages');

      var isValid = true;

      if (!this.get('goal.title')) {
        flashMessages.danger('Your goals success metric cannot be blank');
        isValid = false;
      }

      if (!this.get('goal.steps')) {
        flashMessages.danger('You must provide steps to complete');
        isValid = false;
      }

      if (!this.get('goal.hasCategory')) {
        flashMessages.danger('You must select a category for this goal');
        isValid = false;
      }

      if (!this.get('goal.dueDate')) {
        flashMessages.danger('You must set a goal due date');
        isValid = false;
      }

      return isValid;
    }
  });
});