define('ownersup-client/admin/groups/route', ['exports', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _flashMessageable, _trackableRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashMessageable.default, _trackableRecord.default, {
    metrics: Ember.inject.service(),
    actions: {
      saveRecord: function saveRecord(group) {
        var _this = this;

        // If a user or membership is being saved, pass it off
        if (group.constructor.modelName !== 'group') {
          return true;
        }

        this.trackModelEvent(group);

        return group.save()
        // .then(() => {
        //   return group.get('groupCategories').save();
        // })
        .then(function () {
          _this._displaySuccessMessage('Group Successfully Created');
          _this.transitionTo('admin.groups.group', group);
        }).catch(function (err) {
          _this._displayErrors(err);
        });
      }
    }
  });
});