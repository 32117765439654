define('ownersup-client/components/comment-new/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),

    classNames: ['comment-new'],
    actions: {
      newComment: function newComment() {
        this.sendAction();
      }
    }
  });
});