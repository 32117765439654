define('ownersup-client/components/comment-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),
    classNames: ['comment-form'],

    didInsertElement: function didInsertElement() {
      this.$('textarea').focus();
    },
    willDestroyElement: function willDestroyElement() {
      var comment = this.get('comment');
      if (comment && comment.get('hasDirtyAttributes')) {
        this.sendAction('cancel', comment, false);
      }
    },


    actions: {
      saveComment: function saveComment() {
        var comment = this.get('comment');
        comment.set('isEditing', false);
        this.sendAction('saveComment', comment);
      },
      cancel: function cancel() {
        var comment = this.get('comment');
        this.sendAction('cancel', comment, false);
      }
    }
  });
});