define('ownersup-client/application/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var View = Ember.View;
  exports.default = View.extend({
    session: Ember.inject.service('session'),

    classNameBindings: ['media.classNames', 'session.isAuthenticated']
  });
});