define('ownersup-client/components/report-form/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.maxDate = new Date();
    },

    currentGroup: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    classNames: ['report-form'],
    actions: {
      newActivity: function newActivity(type) {
        this._newActivity(type);
      },
      handleDateChange: function handleDateChange(date) {
        this._updateReportDate(date);
      },
      submit: function submit() {
        var report = Ember.get(this, 'report');

        if (this._validReport()) {
          this.sendAction('save', report, false);
        }
      },
      publish: function publish() {
        Ember.set(this, 'report.status', 'published');
      },
      cancel: function cancel() {
        var report = Ember.get(this, 'report');

        this.sendAction('cancel', report);
      },
      delete: function _delete() {
        var report = Ember.get(this, 'report');

        this.sendAction('delete', report);
      },
      deleteActivity: function deleteActivity(activity) {
        var flashMessages = Ember.get(this, 'flashMessages');

        if (activity.get('isNew')) {
          activity.deleteRecord();
        } else {
          activity.destroyRecord().then(function () {
            flashMessages.success('WrapUp activity has been deleted');
          }).catch(function (err) {
            flashMessages.danger(err.responseText);
          });
        }
      }
    },

    _updateReportDate: function _updateReportDate(date) {
      Ember.set(this, 'report.createdAt', date);
      Ember.get(this, 'report.reportActivities').setEach('createdAt', date);
    },
    _newActivity: function _newActivity(type) {
      var report = Ember.get(this, 'report');

      var _EmberGetProperties = Ember.getProperties(report, 'groupMembership', 'createdAt', 'reportActivities'),
          groupMembership = _EmberGetProperties.groupMembership,
          createdAt = _EmberGetProperties.createdAt,
          reportActivities = _EmberGetProperties.reportActivities;

      this.type = type;

      createdAt = (0, _moment.default)(createdAt).add(Ember.get(reportActivities, 'length'), 's').toDate();

      reportActivities.createRecord({ type: type, groupMembership: groupMembership, createdAt: createdAt });

      function _focusTextarea() {
        this.$('.report-' + this.type + ':last textarea').focus();
      }

      Ember.run.scheduleOnce('afterRender', this, _focusTextarea);
    },
    _validReport: function _validReport() {
      var flashMessages = Ember.get(this, 'flashMessages');

      var accomplishments = Ember.get(this, 'report.accomplishments');

      if (!Ember.get(this, 'report.groupMembership.id')) {
        flashMessages.danger('The groupMembership doesn\'t appear to be set. Please contact support');
        return false;
      }

      if (accomplishments.length === 0 || !accomplishments.get('firstObject.notEmpty')) {
        flashMessages.danger('You need to have at least one accomplishment');

        return false;
      }

      if (!accomplishments.isEvery('hasCategory')) {
        flashMessages.danger('Every accomplishment needs a category');
        return false;
      }

      if (!accomplishments.isEvery('time')) {
        flashMessages.danger('Every accomplishment needs a time');
        return false;
      }

      return true;
    }
  });
});