define('ownersup-client/progress/reports/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.get('reportStatsTask').perform();
    }
  });
});