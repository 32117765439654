define('ownersup-client/transitions/fade-up', ['exports', 'ownersup-client/transitions/fade-direction'], function (exports, _fadeDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    return _fadeDirection.default.call(this, 'y', 1, opts, opts.offset);
  };
});