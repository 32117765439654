define('ownersup-client/components/ownersup-search/component', ['exports', 'ember-hypersearch'], function (exports, _emberHypersearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberHypersearch.default.extend({
    store: Ember.inject.service(),
    request: function request(query) {
      var model = Ember.get(this, 'model');
      var adapter = Ember.get(this, 'store').adapterFor(Ember.get(this, 'model'));
      var url = adapter.buildURL.apply(adapter, [model]);
      var data = { q: query };

      return adapter.ajax.apply(adapter, [url, 'GET', { data: data }]);
    }
  });
});