define('ownersup-client/components/calendar-day/component', ['exports', 'ember-moment/computeds/format', 'moment'], function (exports, _format, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['calendar-day', 'day'],
    classNameBindings: ['active', 'dayContext'],

    dayNumber: (0, _format.default)('date', 'D'),
    dayName: (0, _format.default)('date', 'dd'),

    click: function click() {
      var date = (0, _moment.default)(this.get('date'));
      var today = new Date();

      if (!date.isAfter(today, 'day')) {
        this.sendAction('action', (0, _moment.default)(this.get('date')).format('YYYY-MM-DD'));
      }
    },


    active: Ember.computed('date', 'activeDate', function () {
      var date = (0, _moment.default)(this.get('date'));
      var active = (0, _moment.default)(this.get('activeDate', 'YYYY-MM-DD'));

      return date.isSame(active, 'day');
    }),

    dayContext: Ember.computed('date', function () {
      var date = (0, _moment.default)(this.get('date'));
      var today = new Date();

      if (date.isAfter(today, 'day')) {
        return 'is-future';
      } else if (date.isBefore(today, 'day')) {
        return 'is-past';
      } else {
        return 'today';
      }
    })
  });
});