define('ownersup-client/users/invitation/accept/controller', ['exports', 'ownersup-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['invitation_token', 'email', 'stripe_access_token', 'stripe_plan'],

    stripe_plan: 'ownersup_weekly',
    password: '',
    password_confirmation: '',
    isValid: false,
    saving: false,

    invitation: Ember.computed.readOnly('model.invitation'),
    trial_end: Ember.computed.readOnly('invitation.trial_end'),
    plan: Ember.computed('invitation.stripe_plan_id', function () {
      return _environment.default.APP.stripePlans[this.get('invitation.stripe_plan_id')];
    }),
    amountInCents: Ember.computed('plan', function () {
      return this.get('plan.amount') * 100;
    }),
    shouldCollectPaymentInfo: Ember.computed('plan', function () {
      return this.get('plan.amount') > 0;
    }),

    actions: {
      processStripeToken: function processStripeToken(token) {
        Ember.set(this, 'model.user.token', token);
      },
      setPassword: function setPassword(values) {
        Ember.get(this, 'model.user').setProperties(values);
      }
    }
  });
});