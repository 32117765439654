define('ownersup-client/group/goals/index/route', ['exports', 'ownersup-client/mixins/paginated-route', 'ownersup-client/mixins/group-membership-content-route'], function (exports, _paginatedRoute, _groupMembershipContentRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatedRoute.default, _groupMembershipContentRoute.default, {
    templateName: 'group.index',
    model: function model(params) {
      params.group_membership_id = this._getRouteGroupGroupMemberhipIds();

      return this.get('store').query('goal', params);
    },
    afterModel: function afterModel(model) {
      this._super(model);
      this.get('store').query('comment', { commentable_id: model.mapBy('id') });
    }
  });
});