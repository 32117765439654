define('ownersup-client/group-membership/adapter', ['exports', 'ownersup-client/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    authorizer: 'authorizer:application',
    tallyUpStats: function tallyUpStats(id) {
      var endpoint = this.buildURL('group-membership', id);

      return this.get('ajaxService').request(endpoint + '/stats/tallyups');
    },
    reportStats: function reportStats(id, from) {
      var groupBy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'month';

      var endpoint = this.buildURL('group-membership', id);

      return this.get('ajaxService').request(endpoint + '/stats/reports?from=' + from + '&groupBy=' + groupBy);
    }
  });
});