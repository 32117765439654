define('ownersup-client/ajax/service', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    trustedHosts: ['localhost', 'api.ownersup.com'],
    session: Ember.inject.service(),
    authorizer: 'authorizer:application',
    headers: Ember.computed('session', {
      get: function get() {
        var authorizer = this.get('authorizer');
        var headers = {
          'Accept': 'application/json'
        };
        this.get('session').authorize(authorizer, function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        return headers;
      }
    })
  });
});