define('ownersup-client/report-activity/serializer', ['exports', 'ownersup-client/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    normalize: function normalize(typeClass, hash) {
      if (hash.report_id) {
        hash.reportId = hash.report_id;
      }

      return this._super(typeClass, hash);
    }
  });
});