define('ownersup-client/admin/users/user/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    actions: {
      willTransition: function willTransition() /*transition*/{},
      submit: function submit() {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');

        var user = this.controller.get('model');

        user.save().then(function () {
          _this.transitionTo('admin.users.user', user.id);
          flashMessages.success('Successfully saved!');
        }).catch(function (err) {
          flashMessages.danger(err.message);
        });
      },
      delete: function _delete(user) {
        var _this2 = this;

        var flashMessages = Ember.get(this, 'flashMessages');

        user.destroyRecord().then(function () {
          flashMessages.success('Successfully deleted!');
          _this2.transitionTo('admin.users');
        }).catch(function (err) {
          flashMessages.danger(err.message);
        });
      }
    }
  });
});