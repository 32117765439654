define('ownersup-client/utils/format-error-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formanErrorJson;
  function formanErrorJson(errorObject) {
    if (errorObject.responseJSON) {
      var messages = [];
      var _errors = errorObject.responseJSON;

      for (var error in _errors) {
        messages.push(error + ' ' + _errors[error].join(', '));
      }

      if (messages.length > 0) {
        return messages.join('<br />');
      }
    }

    var errors = errorObject.payload.error;

    if (errors.length > 0) {
      return errors.join('<br />');
    }

    return 'Uh Oh! Something went wrong';
  }
});