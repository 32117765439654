define('ownersup-client/reports/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/flash-messageable', 'ownersup-client/mixins/trackable-record'], function (exports, _authenticatedRouteMixin, _groupMembershipContentRoute, _flashMessageable, _trackableRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _groupMembershipContentRoute.default, _flashMessageable.default, _trackableRecord.default, {
    flashMessages: Ember.inject.service(),

    actions: {
      saveRecord: function saveRecord(report) {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');

        this.trackModelEvent(report);

        report.save().then(function (report) {
          return _this._saveReportActivities(report);
        }).then(function () {
          flashMessages.success('Successfully saved!');
          if (Ember.get(report, 'isPublished')) {
            _this.transitionTo('index');
          }
        }).catch(function (err) {
          _this._displayErrors(err);
        });
      }
    },

    _saveReportActivities: function _saveReportActivities(report) {
      var promises = [];

      report.get('reportActivitiesSorted').forEach(function (item) {
        if (item.get('hasDirtyAttributes') && item.get('notEmpty')) {
          promises.push(item.save());
        }
      });

      return Ember.RSVP.all(promises);
    },
    _isNewButUnmodified: function _isNewButUnmodified(model) {
      return model.get('isNewAndEmpty');
    }
  });
});