define('ownersup-client/admin/groups/group/route', ['exports', 'ownersup-client/mixins/flash-messageable'], function (exports, _flashMessageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashMessageable.default, {
    flashMessages: Ember.inject.service(),

    model: function model(params) {
      return this.get('store').findRecord('group', params.group_id);
    },
    afterModel: function afterModel(model) {
      return model.get('groupMemberships');
    },


    actions: {
      createGroupMembership: function createGroupMembership(user, role) {
        var group = this.controller.get('model');

        var groupMembership = this.get('store').createRecord('groupMembership', { group: group, user: user, role: role });

        this.send('saveRecord', groupMembership, false, 'Awesome! ' + groupMembership.get('user.fullName') + ' has been added to ' + group.get('name'));
      },
      inviteUser: function inviteUser() {
        this._inviteUser.apply(this, arguments);
      },
      deleteUser: function deleteUser(groupMembership, path) {
        this.send('deleteRecord', groupMembership.get('user.content'), path, 'The invited user has been deleted');
      }
    },

    _inviteUser: function _inviteUser(options) {
      var _this = this;

      var flash = Ember.get(this, 'flashMessages');
      var group = Ember.get(this.controller, 'model');
      var role = 'member';

      var api_v1_user = {};

      for (var key in options) {
        api_v1_user[Ember.String.underscore(key)] = options[key];
      }

      options.isInvited = true;

      var user = this._createUser(options);

      api_v1_user.group_id = group.id;

      user.customAction('invitation', 'POST', { api_v1_user: api_v1_user }).then(function () {
        return _this._createGroupMembershp(group, user, role);
      }).then(function () {
        flash.success(options.email + ' has been invited');
      }).catch(function (err) {
        _this._displayErrors(err.errors);
      });
    },
    _createUser: function _createUser(options) {
      return this.get('store').createRecord('user', options);
    },
    _createGroupMembershp: function _createGroupMembershp(group, user, role) {
      return this.get('store').createRecord('groupMembership', { group: group, user: user, role: role });
    }
  });
});