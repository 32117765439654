define('ownersup-client/components/modals/modal-trial-expired/component', ['exports', 'ownersup-client/components/modal-base/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    actions: {
      processStripeToken: function processStripeToken(token) {
        var context = this.get('modalContext');
        return context.send('processStripeToken', token);
      }
    }
  });
});