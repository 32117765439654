define('ownersup-client/mixins/non-rest-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    customAction: function customAction(action, method, data) {
      method = method || 'GET';
      var type = this.constructor.modelName;
      var adapter = this.get('store').adapterFor(type);

      var url = this.getActionUrl(action, adapter);
      var args = [url, method];
      if (data) {
        args.push({ data: data });
      }

      return adapter.ajax.apply(adapter, args);
    },
    getActionUrl: function getActionUrl(action, adapter) {
      var id = this.get('id');
      var args = [this.constructor.modelName];

      if (id) {
        args.push(id);
      }

      var url = adapter.buildURL.apply(adapter, args);

      return url + '/' + action;
    }
  });
});