define('ownersup-client/components/page-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['page-header'],
    classNameBindings: ['media.classNames'],

    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    currentGroup: Ember.inject.service(),

    shouldShowMenu: Ember.computed.and('currentUser.user.isFulfilled', 'session.isAuthenticated'),
    groupListVisible: false,
    activeGroup: Ember.computed.reads('currentGroup.group'),
    multipleGroups: Ember.computed.gt('currentUser.groupMembershipIds.length', 1),
    otherGroupMemberships: Ember.computed.filter('currentUser.user.groupMemberships', function (groupMembership) {
      var activeGroupMembershipId = this.get('currentGroup.activeGroupMembershipId').toString();
      return groupMembership.get('id') !== activeGroupMembershipId;
    }),

    actions: {
      toggleGroupMenu: function toggleGroupMenu() {
        this.toggleProperty('groupListVisible');
      },
      setCurrentGroupMembership: function setCurrentGroupMembership(groupMembership) {
        this.set('groupListVisible', false);
        this.setCurrentGroupMembership(groupMembership);
      }
    }
  });
});