define('ownersup-client/components/scope-bar/component', ['exports', 'ownersup-client/mixins/component-name'], function (exports, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentName.default, {
    type: 'scope',
    label: false,
    classNameBindings: ['labelDasherized'],

    name: Ember.computed('type', function () {
      return Ember.get(this, 'type') + '-bar';
    }),

    labelDasherized: Ember.computed('label', function () {
      var label = Ember.get(this, 'label');
      return label ? label.dasherize() : false;
    })
  });
});