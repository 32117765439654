define('ownersup-client/group-membership/model', ['exports', 'ember-data', 'moment', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/non-rest-action'], function (exports, _emberData, _moment, _timestampable, _authorizable, _nonRestAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_timestampable.default, _authorizable.default, _nonRestAction.default, {
    status: attr('string', { defaultValue: 'active' }),
    isActive: Ember.computed('status', 'isConfirmed', function () {
      return this.get('status') === 'active' && !!this.get('isConfirmed');
    }),
    role: attr('string', { defaultValue: 'member' }),
    monthlyGoalAmount: attr('number', { defaultValue: 0 }),
    monthlyGoalUpdated: attr('date'),

    reportsCount: attr('number'),

    user: belongsTo('user', { async: true, inverse: 'groupMemberships' }),
    isConfirmed: Ember.computed.readOnly('user.isConfirmed'),
    isInvited: Ember.computed.readOnly('user.isInvited'),

    group: belongsTo('group', { async: true, inverse: 'groupMemberships' }),

    reports: hasMany('report', { async: true, inverse: 'groupMembership' }),
    tallyups: hasMany('tallyup', { async: true, inverse: 'groupMembership' }),

    timeZone: Ember.computed.readOnly('user.timeZone'),
    notificationReportReminder: attr('boolean', { defaultValue: true }),
    notificationReportReminderTime: attr('utc-time'),
    notificationReportReminderTimeInZone: Ember.computed('notificationReportReminderTime', function () {
      return this._timeToUserZone('notificationReportReminderTime');
    }),

    notificationGoalReminder: attr('boolean', { defaultValue: true }),
    notificationTallyupReminder: attr('boolean', { defaultValue: true }),
    notificationTallyupReminderTime: attr('utc-time'),
    notificationTallyupReminderTimeInZone: Ember.computed('notificationTallyupReminderTime', function () {
      return this._timeToUserZone('notificationTallyupReminderTime');
    }),

    notificationComments: attr('boolean', { defaultValue: true }),
    notificationNewContent: attr('boolean', { defaultValue: true }),
    notificationWeeklyReport: attr('boolean', { defaultValue: true }),
    notificationWeeklyReportTime: attr('utc-time'),
    notificationWeeklyReportTimeInZone: Ember.computed('notificationWeeklyReportTime', function () {
      return this._timeToUserZone('notificationWeeklyReportTime');
    }),

    goals: hasMany('goal', { async: true }),
    goalsCount: Ember.computed.oneWay('goals.length'),
    activeGoals: Ember.computed.filterBy('goals', 'status', 'active'),
    activeGoalCount: Ember.computed.oneWay('activeGoals.length'),

    archive: function archive() {
      return this.set('status', 'archived');
    },
    _timeToUserZone: function _timeToUserZone(key) {
      var timeZone = Ember.get(this, 'timeZone');
      var reminderTime = Ember.get(this, key);

      return (0, _moment.default)(reminderTime).tz(timeZone).format('h:mm A');
    }
  });
});