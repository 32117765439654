define('ownersup-client/members/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentGroup: Ember.inject.service(),
    model: function model() {
      return Ember.get(this, 'currentGroup.group');
    }

    // setupController(controller, model) {
    //   this._super(controller, model);
    //
    //   let groupMembershipIds = model.group.get('groupMemberships').getEach('id');
    //
    //   controller.set('model.goals',
    //     this.get('store').query('goal', {
    //       groupMembership: groupMembershipIds
    //     })
    //   );
    // }

  });
});