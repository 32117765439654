define('ownersup-client/components/group-form/component', ['exports', 'ownersup-client/mixins/form-component'], function (exports, _formComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_formComponent.default, {
    model: Ember.computed.alias('group'),
    redirectOnSave: 'admin.groups'
  });
});