define('ownersup-client/components/input-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-group', 'input-text'],
    classNameBindings: ['labelClass'],
    type: 'text',
    autofocus: false,
    name: false,

    didInsertElement: function didInsertElement() {
      if (this.get('autofocus')) {
        this.$('input').focus();
      }
    },


    testId: Ember.computed('label', 'name', function () {
      if (this.get('name')) {
        return this.get('name').dasherize();
      }
      if (this.get('label')) {
        return this.get('label').dasherize();
      }
    }),

    labelClass: Ember.computed('label', function () {
      if (this.get('label')) {
        return 'input-' + this.get('label').dasherize();
      }
    }),

    inputId: Ember.computed('elementId', function () {
      return 'input-' + this.get('type') + '-' + this.elementId;
    })
  });
});