define('ownersup-client/mixins/component-attribute-types', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this._validAttrs();
    },
    _validAttrs: function _validAttrs() {
      var attrs = this.attrs,
          attrTypes = this.attrTypes;


      if (attrTypes) {
        (false && !(attrs) && Ember.assert('Component ' + this.toString() + ' did not receive any attributes despite having attributes requirements', attrs));
      }

      for (var key in attrTypes) {
        if (attrTypes[key] === true) {
          (false && !(Object.keys(attrs).includes(key)) && Ember.assert('Component ' + this.toString() + ' requires the attribute ' + key, Object.keys(attrs).includes(key)));
        }
      }

      return true;
    }
  });
});