define('ownersup-client/dashboard/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      createRecord: function createRecord(type) {
        var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return this.get('store').createRecord(type, attributes);
      }
    },
    queryParams: ['activeDate'],
    activeDate: (0, _moment.default)().format('YYYY-MM-DD')
  });
});