define('ownersup-client/category/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),
    slug: attr('string'),
    description: attr('string'),
    groupCategories: hasMany('group-categories', { async: false }),

    isEditing: attr('boolean', { defaultValue: false })
  });
});