define('ownersup-client/progress/reports/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentGroup: Ember.inject.service(),

    queryParams: ['type', 'category_id'],
    type: null,
    category_id: null,
    types: ['Accomplishment', 'Lesson', 'Journal']
  });
});