define('ownersup-client/current-group/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    activeGroupMembershipId: Ember.computed.readOnly('session.data.activeGroupMembershipId'),
    activeGroupMembership: Ember.computed('activeGroupMembershipId', function () {
      var groupMembershipId = Ember.get(this, 'activeGroupMembershipId');
      var store = Ember.get(this, 'store');

      if (!groupMembershipId) {
        return null;
      }

      if (store.hasRecordForId('group-membership', groupMembershipId)) {
        return store.peekRecord('group-membership', groupMembershipId);
      } else {
        return store.findRecord('group-membership', groupMembershipId);
      }
    }),

    group: Ember.computed('activeGroupMembership.group.id', function () {
      var groupId = this.get('activeGroupMembership.group.id');
      return this.get('store').findRecord('group', groupId);
    }),
    name: Ember.computed.readOnly('group.name'),
    id: Ember.computed.readOnly('group.id'),
    model: Ember.computed.readOnly('group'),
    groupMemberships: Ember.computed.readOnly('group.groupMemberships'),
    groupMembershipIds: Ember.computed.mapBy('groupMemberships', 'id'),
    isCurrentUserGroupFacilitator: Ember.computed.equal('activeGroupMembership.role', 'facilitator')
  });
});