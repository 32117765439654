define('ownersup-client/components/modal-billing/component', ['exports', 'ownersup-client/mixins/spinnerable', 'ownersup-client/mixins/flash-messageable'], function (exports, _spinnerable, _flashMessageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_spinnerable.default, _flashMessageable.default, {
    currentUser: Ember.inject.service('current-user'),
    flashMessages: Ember.inject.service(),

    stripePlan: 'ownersup_weekly',
    agreed: false,
    inStepOne: Ember.computed.empty('currentUser.user.stripeCardLast4'),
    inStepTwo: Ember.computed('inStepOne', 'currentUser.user.stripeSubscriptionId', function () {
      return !this.get('inStepOne') && Ember.isNone(this.get('currentUser.user.stripeSubscriptionId'));
    }),
    hasNotAgreed: Ember.computed.not('agreed'),

    actions: {
      processStripeToken: function processStripeToken(token) {
        this._addAccesTokenToUser(token);
      },
      confirmSubscription: function confirmSubscription() {
        this._addSubscriptionToUser();
      }
    },

    _addAccesTokenToUser: function _addAccesTokenToUser(token) {
      var _this = this;

      var user = this.get('currentUser.user');
      var currentPassword = this.get('currentPassword');

      this.start();
      user.setProperties({ currentPassword: currentPassword, token: token });
      user.save().then(function () {
        user.reload();
      }, function (err) {
        _this._displayErrors(err);
      }).finally(function () {
        _this.done();
      });
    },
    _addSubscriptionToUser: function _addSubscriptionToUser() {
      var _this2 = this;

      var flash = Ember.get(this, 'flashMessages');
      var currentUser = this.get('currentUser');

      var properties = this.getProperties('stripePlan', 'agreed', 'currentPassword');

      this.start();
      currentUser.get('user').setProperties(properties).save().then(function () {
        flash.success('You are now enrolled in automatic billing');
        _this2.sendAction('closeAction');
      }, function (err) {
        _this2._displayErrors(err);
      }).finally(function () {
        _this2.done();
      });
    }
  });
});