define('ownersup-client/components/activity-list/component', ['exports', 'moment', 'ownersup-client/mixins/component-attribute-types'], function (exports, _moment, _componentAttributeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentAttributeTypes.default, {
    classNames: ['activity-list'],
    attrTypes: {
      save: true,
      cancel: true,
      newComment: true,
      model: true,
      activeDate: true
    },

    actions: {
      save: function save(record) {
        this.sendAction('save', record);
      },
      cancel: function cancel(record) {
        this.sendAction('cancel', record);
      },
      newComment: function newComment(comment) {
        this.sendAction('newComment', comment);
      }
    },

    model: Ember.computed.oneWay('attrs.model'),
    activity: Ember.computed.union('model.reports', 'model.olderReports', 'model.requests', 'model.goals'),
    filteredActivity: Ember.computed('attrs.activeDate', 'activity', function () {
      var _this = this;

      return this.get('activity').filter(function (item) {
        var activeDate = (0, _moment.default)(_this.get('activeDate'), 'YYYY-MM-DD');
        return (0, _moment.default)(item.get('createdAt')).isSame(activeDate, 'day');
      });
    }),
    activitySorting: ['createdAt:desc'],
    sortedActivity: Ember.computed.sort('filteredActivity', 'activitySorting')
  });
});