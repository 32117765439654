define('ownersup-client/progress/tallyups/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      editTallyup: function editTallyup(model) {
        model.toggleProperty('isEditing');
      }
    }
  });
});