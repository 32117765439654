define('ownersup-client/components/category-form/component', ['exports', 'ownersup-client/mixins/form-component'], function (exports, _formComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_formComponent.default, {
    classNames: ['well'],
    model: Ember.computed.alias('category')
  });
});