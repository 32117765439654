define('ownersup-client/components/tab-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tab-bar-container'],
    showAddMenu: false,

    actions: {
      toggleAddMenu: function toggleAddMenu() {
        this.toggleProperty('showAddMenu');
      }
    }
  });
});