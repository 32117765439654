define("ownersup-client/utils/report-stats", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.totalMinutes = totalMinutes;
  exports.categoryTotals = categoryTotals;
  function totalMinutesReducer(total, group) {
    var groupTotal = 0;

    for (var key in group) {
      if (group[key].total_minutes) {
        groupTotal = groupTotal + group[key].total_minutes;
      }
    }

    return total + groupTotal;
  }

  function totalMinutes() {
    var stats = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    // Not sure why typescript doesn't like this
    return stats.reduce(totalMinutesReducer, 0);
  }

  function sumCategories(json, currentCategories) {
    var stats = {};

    json.forEach(function (group) {
      for (var category in group) {
        if (!group[category].id || !currentCategories.includes(category)) {
          continue;
        }

        if (!stats[category]) {
          stats[category] = { id: 0, count: 0, minutes: 0 };
        }

        stats[category].id = group[category].id;
        stats[category].count += group[category].count;
        stats[category].minutes += group[category].total_minutes;
      }
    });

    return stats;
  }

  function categoryTotals(json, currentCategories, totalMinutes) {
    var stats = sumCategories(json, currentCategories);
    var categories = [];

    for (var category in stats) {
      categories.push({
        name: category,
        id: stats[category].id,
        count: stats[category].count,
        minutes: stats[category].minutes,
        percentage: Math.round(stats[category].minutes / totalMinutes * 100)
      });
    }

    return categories;
  }
});