define('ownersup-client/helpers/format-money', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMoney = formatMoney;
  function formatMoney(params /*, hash*/) {
    var number = '' + parseInt(params);

    number = number.replace(/\d(?=(\d{3})+$)/g, '$&,');

    return ('<span class="symbol">$</span>' + number).htmlSafe();
  }

  exports.default = Ember.Helper.helper(formatMoney);
});