define('ownersup-client/commentable/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable'], function (exports, _emberData, _timestampable, _authorizable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_timestampable.default, _authorizable.default, {
    comments: hasMany('comment', { async: true }),
    type: attr('string'),
    commentsCount: attr('number', { defaultValue: 0 })
  });
});