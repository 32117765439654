define('ownersup-client/components/progress-bar/component', ['exports', 'ownersup-client/mixins/component-name', 'ember-prop-types', 'ownersup-client/helpers/number-to-k'], function (exports, _componentName, _emberPropTypes, _numberToK) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentName.default, {
    name: 'progress-bar',
    propTypes: {
      max: _emberPropTypes.PropTypes.number.isRequired,
      current: _emberPropTypes.PropTypes.number.isRequired,
      showNumbers: _emberPropTypes.PropTypes.bool
    },
    showNumbers: false,
    percentage: Ember.computed('max', 'current', function () {
      var current = this.get('current');
      if (!current) {
        return 0;
      }
      return Math.round(current / Ember.get(this, 'max') * 100);
    }),
    almostThere: Ember.computed.gte('percentage', 80),
    isComplete: Ember.computed.gte('percentage', 100),
    progressBarStyle: Ember.computed('percentage', function () {
      var width = 'width: ' + Ember.get(this, 'percentage') + '%';
      return Ember.String.htmlSafe(width);
    }),

    meterLabel: Ember.computed('showNumbers', 'current', 'max', 'almostThere', function () {
      var html = void 0;
      if (this.get('almostThere')) {
        html = (0, _numberToK.numberToK)(this.get('current')) + ' of ' + (0, _numberToK.numberToK)(this.get('max'));
      } else {
        html = (0, _numberToK.numberToK)(this.get('current'));
      }
      return Ember.String.htmlSafe(html);
    })
  });
});