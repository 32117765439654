define('ownersup-client/components/eyebrow-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),

    classNames: ['eyebrow-menu'],
    userPopoverVisible: false,

    actions: {
      toggleUserPopver: function toggleUserPopver() {
        this.toggleProperty('userPopoverVisible');
      },
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});