define('ownersup-client/goals/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service('current-user'),

    model: function model() {
      return Ember.RSVP.hash({
        activeGoals: this.get('store').query('goal', {
          status: 'active',
          group_membership_ids: this.get('currentUser.groupMembershipIds')
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties(model);

      controller.set('model.completedGoals', this.get('store').query('goal', {
        status: 'completed',
        group_membership_ids: this.get('currentUser.groupMembershipIds')
      }));
    }
  });
});