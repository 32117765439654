define('ownersup-client/report-activity/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/categorizable'], function (exports, _emberData, _timestampable, _categorizable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_timestampable.default, _categorizable.default, {
    title: attr('string'),
    description: attr('string'),
    type: attr('string'),
    time: attr('number'),

    report: belongsTo('report', { async: true }),
    reportId: attr('string'),
    category: belongsTo('category', { async: true }),

    notEmpty: Ember.computed.notEmpty('description')
  });
});