define('ownersup-client/components/modals/modal-notification-time-settings/component', ['exports', 'moment', 'ownersup-client/components/modal-base/component'], function (exports, _moment, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    groupMembership: Ember.computed.alias('modalContext'),
    timeZone: Ember.computed.readOnly('groupMembership.user.timeZone'),

    availableTimes: Ember.computed(function () {
      var times = [];
      var periods = ['AM', 'PM'];
      var hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      var minutes = ['00', '30'];

      periods.forEach(function (period) {
        hours.forEach(function (hour) {
          minutes.forEach(function (minute) {
            times.push(hour + ':' + minute + ' ' + period);
          });
        });
      });

      return times;
    }),

    actions: {
      updateReminderTime: function updateReminderTime(key, selection) {
        var timeZone = Ember.get(this, 'timeZone');
        var value = _moment.default.tz(selection, 'h:mm A', timeZone).tz('UTC');

        Ember.set(this, 'groupMembership.' + key, value);
      },
      handleSave: function handleSave() {
        var groupMembership = Ember.get(this, 'groupMembership');

        this._save(groupMembership, 'Success! Your reminder time has been updated');
      }
    }
  });
});