define('ownersup-client/admin/groups/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ids: [],
    filteredResults: Ember.computed('model.@each.id', {
      get: function get() {
        var values = Ember.get(this, 'ids');
        return Ember.A(Ember.get(this, 'model')).filter(function (item) {
          return Ember.A(values).includes(parseInt(item.id));
        });
      }
    }),
    results: Ember.computed('model', 'ids', function () {
      var key = Ember.get(this, 'ids.length') ? 'filteredResults' : 'model';

      return Ember.get(this, key);
    }),

    actions: {
      handleResults: function handleResults(results) {
        var groups = results.groups;

        this.get('store').pushPayload({ groups: groups });

        this.set('ids', groups.mapBy('id'));
      }
    }
  });
});