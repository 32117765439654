define('ownersup-client/current-user/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    role: Ember.computed.reads('session.data.authenticated.role'),
    isAdmin: Ember.computed.equal('role', 'admin'),
    isUser: Ember.computed.equal('role', 'user'),

    id: Ember.computed.reads('session.data.authenticated.id'),
    groupMembershipIds: Ember.computed.reads('user.groupMembershipIds'),
    hasGroupMemberships: Ember.computed.bool('groupMembershipIds'),

    user: Ember.computed('id', function () {
      var id = Ember.get(this, 'id');

      if (Ember.isPresent(id)) {
        return Ember.get(this, 'store').findRecord('user', id);
      }

      return null;
    })
  });
});