define('ownersup-client/application/adapter', ['exports', 'ownersup-client/config/environment', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-ajax/mixins/ajax-support'], function (exports, _environment, _activeModelAdapter, _dataAdapterMixin, _ajaxSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    authorizer: 'authorizer:application',
    coalesceFindRequests: true,
    namespace: 'v1',
    host: _environment.default.APP.API_HOST,
    headers: {
      'Accept': 'application/json'
    },

    shouldReloadAll: function shouldReloadAll() /*store, snapshotRecordArray*/{
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() /*store, snapshotRecordArray*/{
      return true;
    },
    shouldReloadRecord: function shouldReloadRecord() /*store, snapshot*/{
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() /*store, snapshot*/{
      return false;
    }
  });
});