define('ownersup-client/components/input-search/component', ['exports', 'ownersup-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.ids = [];
      this.query = null;
      this.hasSearched = false;
    },


    stripePlanId: 'ownersup_297',
    stripeCouponId: null,
    store: Ember.inject.service(),
    stripePlans: Ember.computed(function () {
      var plans = _environment.default.APP.stripePlans;
      return Object.keys(plans).map(function (planId) {
        var plan = plans[planId];
        Ember.set(plan, 'key', planId);
        return plan;
      }).sort(function (planA, planB) {
        return planB.amount - planA.amount;
      });
    }),

    actions: {
      handleResults: function handleResults(results) {
        var users = results.users;

        Ember.get(this, 'store').pushPayload({ users: users });
        Ember.set(this, 'ids', users.mapBy('id').map(function (id) {
          return parseInt(id);
        }));
        Ember.set(this, 'hasSearched', true);
      },
      createGroupMembership: function createGroupMembership(user, role) {
        this.sendAction('createGroupMembership', user, role);
        this._clearForm();
      },
      inviteUser: function inviteUser() {
        var options = this.getProperties('email', 'stripePlanId', 'stripeCouponId', 'stripeTrialEnd');
        this.sendAction('inviteUser', options);
        this._clearForm();
      }
    },

    results: Ember.computed(function () {
      return this.get('store').peekAll('user');
    }),

    filteredResults: Ember.computed('results.@each.id', 'ids', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'ids', 'results'),
          ids = _EmberGetProperties.ids,
          results = _EmberGetProperties.results;

      return results.filter(function (user) {
        return ids.includes(parseInt(user.id));
      });
    }),

    _clearForm: function _clearForm() {
      this.setProperties({
        query: '',
        ids: [],
        hasSearched: false
      });
    }
  });
});