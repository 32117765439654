define('ownersup-client/components/content-table/component', ['exports', 'ownersup-client/mixins/component-attribute-types', 'ownersup-client/mixins/component-name'], function (exports, _componentAttributeTypes, _componentName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentAttributeTypes.default, _componentName.default, {
    name: 'content-table',

    attrTypes: {
      content: true
    },

    // contentType: computed.reads('content.firstObject.constructor.modelName'),
    tableName: Ember.computed(function () {
      return (this.get('content.firstObject.constructor.modelName') || 'content') + '-table';
    }),

    currentPage: Ember.computed.reads('content.meta.pagination.current_page'),
    totalPages: Ember.computed.reads('content.meta.pagination.total_pages'),
    totalCount: Ember.computed.reads('content.meta.pagination.total_count'),

    content: Ember.computed.reads('attrs.content'),
    listSort: ['createdAt:desc'],
    list: Ember.computed.sort('content', 'listSort'),

    actions: {
      save: function save(record) {
        this.sendAction('save', record);
      },
      cancel: function cancel(record, path) {
        this.sendAction('cancel', record, path);
      },
      newComment: function newComment(comment) {
        this.sendAction('newComment', comment);
      }
    }
  });
});