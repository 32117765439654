define('ownersup-client/mixins/paginated-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    concatenatedProperties: ['queryParams'],
    queryParams: ['page', 'page_size'],
    page: 1,
    page_size: 10,

    actions: {
      pageChanged: function pageChanged(current) {
        this.set('page', current);
      }
    }
  });
});