define('ownersup-client/user/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable', 'ownersup-client/mixins/non-rest-action'], function (exports, _emberData, _timestampable, _authorizable, _nonRestAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_timestampable.default, _authorizable.default, _nonRestAction.default, {
    subscription: belongsTo('subscription', { async: false }),
    isTrialing: Ember.computed.oneWay('subscription.inTrial'),

    isUser: Ember.computed.equal('role', 'user'),
    isAdmin: Ember.computed.equal('role', 'admin'),
    isBillable: Ember.computed.oneWay('isUser'),

    firstName: attr('string'),
    lastName: attr('string'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var _getProperties = this.getProperties('firstName', 'lastName'),
          firstName = _getProperties.firstName,
          lastName = _getProperties.lastName;

      return firstName + ' ' + lastName;
    }),

    email: attr('string'),
    role: attr('string', { defaultValue: 'user' }),
    companyName: attr('string'),
    companyWebsite: attr('string'),
    bio: attr('string'),
    twitterUsername: attr('string'),
    facebookUsername: attr('string'),
    instagramUsername: attr('string'),
    linkedInUrl: attr('string'),
    birthday: attr('date'),

    timeZone: attr('string'),

    confirmedAt: attr('date'),
    isConfirmed: attr('boolean'),

    agreed: attr('boolean'), // Terms and Privacy

    invitationToken: attr('string'),
    invitedToSignUp: attr('boolean'),
    isInvited: Ember.computed.oneWay('invitedToSignUp'),
    invitationSentAt: attr('date'),

    notificationNewsletter: attr('boolean'),

    currentPassword: attr('string'),
    password: attr('string'),
    passwordConfirmation: attr('string'),
    stripeCustomerId: attr('string'),
    stripeAccessToken: attr('string'),
    stripePlanId: attr('string'),
    stripeCardLast4: attr('string'),
    stripeCardBrand: attr('string'),
    stripeCardFunding: attr('string'),
    stripeCardExpMonth: attr('string'),
    stripeCardExpYear: attr('string'),
    subscriptionId: attr('string'),

    token: Ember.computed({
      set: function set(key, token) {
        return this.setProperties({
          stripeAccessToken: token.id,
          stripeCardLast4: token.card.last4,
          stripeCardBrand: token.card.brand,
          stripeCardFunding: token.card.funding,
          stripeCardExpMonth: token.card.exp_month,
          stripeCardExpYear: token.card.exp_year
        });
      },
      get: function get() {
        return Ember.get(this, 'stripeAccessToken');
      }
    }),
    intercomUserHash: attr('string'),

    isCreditCardOnFile: Ember.computed.notEmpty('stripeCardLast4'),

    groupMemberships: hasMany('groupMemberships', {
      inverse: 'user',
      async: false
    }),
    groupMembershipIds: Ember.computed.mapBy('groupMemberships', 'id'),
    groupMembershipsCount: Ember.computed.readOnly('groupMemberships.length'),
    groups: Ember.computed.mapBy('groupMemberships', 'group'),

    // Actions & Events

    didCreate: function didCreate() {
      this._clearSensativeData();
    },
    didUpdate: function didUpdate() {
      this._clearSensativeData();
    },


    // Private

    _clearSensativeData: function _clearSensativeData() {
      this.setProperties({
        password: '',
        currentPassword: null,
        passwordConfirmation: null,
        stripeAccessToken: null
      });
    }
  });
});