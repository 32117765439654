define('ownersup-client/admin/categories/index/route', ['exports', 'ownersup-client/mixins/paginated-route'], function (exports, _paginatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatedRoute.default, {
    flashMessages: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').query('category', params);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('showNewCategory', false);
    },


    actions: {
      showNewCategoryForm: function showNewCategoryForm() {
        var newCategory = this.get('store').createRecord('category');

        this.controller.setProperties({
          showNewCategory: true,
          newCategory: newCategory
        });
      },
      editCategory: function editCategory(category) {
        category.toggleProperty('isEditing');
      },
      saveCategory: function saveCategory(category) {
        var _this = this;

        var flashMessages = this.get('flashMessages');
        category.save().then(function () {
          flashMessages.success('Category created!');
          _this.controller.set('showNewCategory', false);
          _this.refresh();
        });
      }
    }
  });
});