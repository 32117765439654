define('ownersup-client/mixins/form-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this.$('textarea,input').eq(0).focus();
    },
    willRender: function willRender() {
      (false && !(this.get('model')) && Ember.assert('You must alias your component model to `model`', this.get('model')));
      (false && !(Ember.get(this, 'save')) && Ember.assert('You must pass a save action', Ember.get(this, 'save')));
      (false && !(Ember.get(this, 'cancel')) && Ember.assert('You must pass a cancel action', Ember.get(this, 'cancel')));
    },
    willDestroyElement: function willDestroyElement() {
      var model = this.get('model');
      if (model && model.get('hasDirtyAttributes')) {
        this.sendAction('cancel', model, false);
      }
    },


    actions: {
      save: function save() {
        var model = this.get('model');
        model.set('isEditing', false);

        var redirect = this.getWithDefault('redirectOnSave', false);
        this.sendAction('save', model, redirect);
      },
      cancel: function cancel() {
        var model = this.get('model');
        this.sendAction('cancel', model, false);
      },
      delete: function _delete() {
        var model = this.get('model');
        var redirect = this.getWithDefault('redirectOnSave', false);
        this.sendAction('delete', model, redirect);
      }
    }
  });
});