define('ownersup-client/admin/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    flashMessages: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (!this.get('currentUser.isAdmin')) {
        this.transitionTo('index');
      }
    },


    actions: {
      archiveGroupMembership: function archiveGroupMembership(groupMembership) {
        this._archiveGroupMembership(groupMembership);
      },
      chooseNewGroupForGroupMembership: function chooseNewGroupForGroupMembership(groupMembership) {
        this._chooseNewGroupForGroupMembership(groupMembership);
      }
    },

    _archiveGroupMembership: function _archiveGroupMembership(groupMembership) {
      var _this = this;

      var flashMessages = Ember.get(this, 'flashMessages');

      groupMembership.archive();
      groupMembership.save().then(function () {
        flashMessages.success('The group membership has been archived');
      }, function (err) {
        _this._displayErrors(err);
      });
    },
    _chooseNewGroupForGroupMembership: function _chooseNewGroupForGroupMembership(groupMembership) {
      this.controllerFor('application').send('showModal', 'modals/modal-move-group-membership', { groupMembership: groupMembership });
    }
  });
});