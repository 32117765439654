define('ownersup-client/user/adapter', ['exports', 'ownersup-client/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    resetPassword: function resetPassword(properties) {
      var endpoint = this.buildURL('user');
      var data = { api_v1_user: properties };
      return this.get('ajaxService').put(endpoint + '/password', { data: data });
    },
    requestNewPassword: function requestNewPassword(email) {
      var endpoint = this.buildURL('user');
      var data = { api_v1_user: { email: email } };
      return this.get("ajaxService").request(endpoint + '/password', {
        data: data,
        method: "post",
        dataType: "text"
      });
    },
    checkConfirmationToken: function checkConfirmationToken(token) {
      var endpoint = this.buildURL('user');
      return this.get('ajaxService').request(endpoint + '/confirmation?confirmation_token=' + token);
    },
    sendConfirmationEmail: function sendConfirmationEmail(email) {
      var endpoint = this.buildURL('user');
      var data = { api_v1_user: { email: email } };
      return this.get('ajaxService').post(endpoint + '/confirmation', { data: data });
    }
  });
});