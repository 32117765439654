define('ownersup-client/users/confirmation/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var userAdapter = this.get('store').adapterFor('user');
      var flashMessages = Ember.get(this, 'flashMessages');
      var token = transition.queryParams.confirmation_token;

      if (token) {
        userAdapter.checkConfirmationToken(token).then(function () {
          flashMessages.success('You\'re account has been confirmed! If you are not logged in, please do so now');
          return _this.transitionTo('/');
        }, function (error) {
          flashMessages.danger(error.responseText);
          return Ember.RSVP.reject(error);
        });
      }
    },


    actions: {
      resendConfirmation: function resendConfirmation() {
        var _this2 = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var userAdapter = this.get('store').adapterFor('user');
        var email = this.controller.get('email');

        return userAdapter.sendConfirmationEmail(email).then(function () {
          flashMessages.success('Please check your email for a new confirmation email');
          return _this2.transitionTo('/login');
        }, function (error) {
          flashMessages.danger(error.responseText);
          return Ember.RSVP.reject(error);
        });
      }
    }
  });
});