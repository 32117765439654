define('ownersup-client/account/billing/route', ['exports', 'ownersup-client/mixins/flash-messageable'], function (exports, _flashMessageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashMessageable.default, {
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    model: function model() {
      return this.get('currentUser.user');
    },


    actions: {
      confirmCardUpdate: function confirmCardUpdate() {
        var _this = this;

        var flash = Ember.get(this, 'flashMessages');
        var user = this.get('controller.model');

        var _controller$getProper = this.controller.getProperties('token', 'currentPassword'),
            token = _controller$getProper.token,
            currentPassword = _controller$getProper.currentPassword;

        if (!token) {
          flash.danger('Looks like something broke. Try entering your card info again');
          this.controller.set('askForPassword', false);
        }

        if (!currentPassword) {
          flash.danger('Hey there. Make sure you fill out your current password');
          return false;
        }

        user.setProperties({ currentPassword: currentPassword, token: token });
        user.save().then(function () {
          user.reload();
          _this.controller.set('askForPassword', false);
          flash.success('Sweet! Your credit card has been updated');
        }, function (err) {
          _this._displayErrors(err);
        });
      }
    }
  });
});