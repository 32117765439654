define('ownersup-client/components/input-password/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['input-password'],
    classNameBindings: ['isValid:valid:invalid', 'isLongEnough::is-short', 'isMatched:matched:not-matched', 'strengthClassName', 'hasPassword', 'hasConfirmation'],

    password: '',
    passwordConfirmation: null,
    minimumLength: 8,

    didInsertElement: function didInsertElement() {
      this._sendActionOnInputBlur();
    },
    willDestroyElement: function willDestroyElement() {
      this._removeInputBlurListener();
    },


    hasPassword: Ember.computed.notEmpty('password'),
    hasConfirmation: Ember.computed.notEmpty('passwordConfirmation'),

    strength: Ember.computed('password', function () {
      // Taken from: https://github.com/matoilic/jquery.pwstrength/blob/master/jquery.pwstrength.js
      var password = this.get('password') || '';
      var length = password.length;


      var hasLowerCase = password.match(/[a-z]/g);
      var hasUpperCase = password.match(/[A-Z]/g);
      var hasDigits = password.match(/\d/g);
      var hasNonAlpha = password.match(/\W/g);
      var hasSpaces = password.match(/\s/g);

      var score = 0;

      if (length < 5) {
        score += 0;
      } else if (length < 8) {
        score += 5;
      } else if (length < 16) {
        score += 10;
      } else {
        score += 15;
      }

      if (hasLowerCase) {
        score += 1;
      }
      if (hasUpperCase) {
        score += 5;
      }
      if (hasUpperCase && hasLowerCase) {
        score += 2;
      }
      if (hasDigits && hasDigits.length > 1) {
        score += 5;
      }
      if (hasNonAlpha) {
        score += 10;
      }
      if (hasNonAlpha && hasNonAlpha.length > 1) {
        score += 5;
      }
      if (hasUpperCase && hasLowerCase && hasDigits && hasNonAlpha) {
        score += 15;
      }
      if (hasSpaces) {
        score += 10;
      }

      return score;
    }),

    strengthHuman: Ember.computed('strength', function () {
      var score = this.get('strength');
      var messages = ['Very Weak', 'Weak', 'Mediocre', 'Strong', 'Super Strong!'];
      var scoreKey = void 0;

      if (score < 15) {
        scoreKey = 0;
      } else if (score < 20) {
        scoreKey = 1;
      } else if (score < 35) {
        scoreKey = 2;
      } else if (score < 50) {
        scoreKey = 3;
      } else {
        scoreKey = 4;
      }

      return messages[scoreKey];
    }),

    strengthClassName: Ember.computed('strengthHuman', function () {
      return this.get('strengthHuman').dasherize();
    }),

    isLongEnough: Ember.computed('password', 'minimumLength', function () {
      return this.get('password.length') >= this.get('minimumLength');
    }),

    isMatched: Ember.computed('password', 'passwordConfirmation', function () {
      return this.get('password') === this.get('passwordConfirmation');
    }),

    showMatched: Ember.computed.and('hasPassword', 'hasConfirmation'),

    isValid: Ember.computed('isLongEnough', 'isMatched', function () {
      return this.get('isLongEnough') && this.get('isMatched');
    }),

    _sendActionOnInputBlur: function _sendActionOnInputBlur() {
      var _this = this;

      this.$().on('blur', 'input', function () {
        var options = _this.getProperties('password', 'passwordConfirmation');

        _this.sendAction('passwordUpdated', options);
      });
    },
    _removeInputBlurListener: function _removeInputBlurListener() {
      this.$().off('blur', 'input');
    }
  });
});