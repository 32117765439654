define('ownersup-client/group/reports/index/route', ['exports', 'ownersup-client/mixins/paginated-route', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _paginatedRoute, _groupMembershipContentRoute, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScroll.default, _paginatedRoute.default, _groupMembershipContentRoute.default, {
    templateName: 'group.index',
    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      params.group_membership_id = this._getRouteGroupGroupMemberhipIds();

      return this.get('store').query('report', params);
    },
    afterModel: function afterModel(model) {
      this._super(model);
      this.get('store').query('comment', { commentable_id: model.mapBy('id') });
    }
  });
});