define('ownersup-client/helpers/time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.time = time;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var EmberS = Ember.String;
  function time(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        date = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        relative = _ref2.relative,
        showLong = _ref2.showLong;

    date = (0, _moment.default)(date);
    var format = showLong ? 'dddd, MMMM Do YYYY' : 'M/D/YY';
    var formattedDate = date.format(format);

    var dateString = void 0;

    if (relative) {
      var ago = date.fromNow();
      dateString = '<span class="relative">' + ago + '</span><span class="full">' + formattedDate + '</span>';
    } else {
      dateString = formattedDate;
    }

    var html = '<time datetime="' + date.toISOString() + '">' + dateString + '</time>';

    return EmberS.htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(time);
});