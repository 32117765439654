define('ownersup-client/components/button-confirmable/component', ['exports', 'ownersup-client/mixins/component-name', 'ownersup-client/mixins/confirmable'], function (exports, _componentName, _confirmable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_componentName.default, _confirmable.default, {
    name: 'button-confirmable',
    classNameBindings: ['isConfirming'],
    shouldConfirm: true,
    verb: 'delete',
    label: Ember.computed('verb', function () {
      return this.get('verb').capitalize();
    }),

    actions: {
      confirm: function confirm() {
        if (Ember.get(this, 'shouldConfirm')) {
          Ember.set(this, 'isConfirming', true);
        } else {
          this.send('confirmed');
        }
      },
      confirmed: function confirmed() {
        this._handleAction('confirmed', this.get('model'), false);
      }
    },

    _handleAction: function _handleAction(actionName) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (Ember.typeOf(this[actionName]) === 'function') {
        this[actionName].apply(this, _toConsumableArray(args));
      } else {
        this.sendAction.apply(this, [actionName].concat(_toConsumableArray(args)));
      }
    }
  });
});