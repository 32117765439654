define('ownersup-client/utils/bugsnag', ['exports', 'ember-cli-bugsnag/utils/bugsnag'], function (exports, _bugsnag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'getMetaData', {
    enumerable: true,
    get: function () {
      return _bugsnag.getMetaData;
    }
  });
  Object.defineProperty(exports, 'getUser', {
    enumerable: true,
    get: function () {
      return _bugsnag.getUser;
    }
  });
});