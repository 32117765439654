define('ownersup-client/components/chart-reports/component', ['exports', 'ownersup-client/components/chart-base/component', 'ownersup-client/config/environment', 'ownersup-client/helpers/format-duration'], function (exports, _component, _environment, _formatDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['chart-reports'],

    init: function init() {
      this._super.apply(this, arguments);

      this.json = this.json || [];
    },


    isEmpty: Ember.computed.lt('json.length', 1),

    groupByFormat: Ember.computed('groupBy', function () {
      var groupBy = this.get('groupBy');
      if (groupBy === 'month') {
        return 'MMMM';
      } else if (groupBy === 'week') {
        return 'YYYY-WW';
      } else {
        return 'YYYY-MM-DD';
      }
    }),

    categoryNames: Ember.computed.mapBy('categories', 'name'),
    categoryChartKeys: Ember.computed.map('categoryNames', function (category) {
      return category + '.total_minutes';
    }),

    dataNames: Ember.computed('categoryChartKeys', function () {
      var categoryChartKeys = this.get('categoryChartKeys');
      var names = {};

      categoryChartKeys.forEach(function (category) {
        names[category] = category.replace('.total_minutes', '');
      });

      return names;
    }),

    data: Ember.computed('json', 'categoryChartKeys', 'dataNames', function () {
      var keys = this.get('categoryChartKeys');
      var data = {
        json: this.get('json'),
        keys: {
          x: 'date', // it's possible to specify 'x' when category axis
          value: keys
        },
        type: 'bar',
        order: 'asc',
        colors: _environment.default.APP.categoryColors,
        groups: [keys],
        names: this.get('dataNames')
      };
      return data;
    }),

    grid: {
      y: {
        show: true
      }
    },

    axis: Ember.computed('groupBy', function () {
      return {
        x: {
          type: 'timeseries',
          tick: {
            fit: true
          }
        }
      };
    }),

    tooltip: Ember.computed('groupBy', function () {
      return {
        contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
          var html = ['<table class="c3-tooltip"><tbody>\n          <tr><th colspan="2">' + defaultTitleFormat(d[0].x) + '</th></tr>'];

          d.sort(function (a, b) {
            return b.value - a.value;
          });

          d.forEach(function (row) {
            html.push('<tr class="c3-tooltip-name-' + row.name + '">\n            <td class="name"><span style="background-color:' + color(row.id) + '"></span>' + row.name.replace('.total_minutes', '') + '</td>\n            <td class="value">' + (0, _formatDuration.formatDuration)(row.value) + '</td>\n          </tr>');
          });
          html.push('</tbody></table>');

          return html.join('');
        }
      };
    })
  });
});