define('ownersup-client/components/report-item/component', ['exports', 'ember-group-by'], function (exports, _emberGroupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['report-item-content'],

    report: Ember.computed.alias('commentable'),
    journals: Ember.computed.filterBy('report.reportActivities', 'type', 'Journal'),
    reportActivities: Ember.computed.filter('report.reportActivities', function (activity) {
      return activity && activity.get('type') !== 'Journal';
    }),
    groupedReportActivities: (0, _emberGroupBy.default)('reportActivities', 'type')
  });
});