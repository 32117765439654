define('ownersup-client/mixins/component-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(this.get('name')) && Ember.assert('Component ' + this.toString() + ' requires the attribute \'name\' to be set', this.get('name')));
    },


    classNameBindings: ['name'],
    attributeBindings: ['name']
  });
});