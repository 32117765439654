define('ownersup-client/initializers/current-user', ['exports', 'ownersup-client/current-user/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    application.register('service:current-user', _service.default);
    application.inject('controller', 'currentUser', 'service:current-user');
  }

  exports.default = { name: 'current-user', initialize: initialize };
});