define('ownersup-client/progress/goals/goal/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('store').findRecord('goal', params.goal_id);
    }
  });
});