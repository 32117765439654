define('ownersup-client/components/content-list/component', ['exports', 'ember-group-by', 'ownersup-client/mixins/component-attribute-types'], function (exports, _emberGroupBy, _componentAttributeTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentAttributeTypes.default, {
    classNames: ['content-list'],

    attrTypes: {
      page: true,
      pageChanged: true,
      newComment: true,
      save: true,
      cancel: true
    },

    pagination: Ember.computed.oneWay('content.meta.pagination'),

    content: Ember.computed.oneWay('attrs.content'),
    listSort: ['createdAt:desc'],
    list: Ember.computed.sort('content', 'listSort'),
    groupedList: (0, _emberGroupBy.default)('list', 'createdDate'),

    actions: {
      pageChanged: function pageChanged(current, previous) {
        this.sendAction('pageChanged', current, previous);
      },
      save: function save(record) {
        this.sendAction('save', record);
      },
      cancel: function cancel(record, path) {
        this.sendAction('cancel', record, path);
      },
      newComment: function newComment(comment) {
        this.sendAction('newComment', comment);
      }
    }
  });
});