define('ownersup-client/users/password/edit/route', ['exports', 'ownersup-client/utils/format-error-json'], function (exports, _formatErrorJson) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = transition.queryParams.reset_password_token;

      if (!token) {
        Ember.get(this, 'flashMessages').danger('The reset password token is invalid or not present. Please request a new token');
        return this.transitionTo('/users/password/new');
      }
    },


    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var userAdapter = this.get('store').adapterFor('user');
        var data = this.controller.getProperties('reset_password_token', 'password', 'password_confirmation');

        userAdapter.resetPassword(data).then(function () {
          flashMessages.success('Success! Now take that new password for a spin and try logging in');
          return _this.transitionTo('/login');
        }, function (error) {
          flashMessages.danger((0, _formatErrorJson.default)(error));
        });
      }
    }
  });
});