define('ownersup-client/goals/new/route', ['exports', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll'], function (exports, _groupMembershipContentRoute, _resetScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_groupMembershipContentRoute.default, _resetScroll.default, {
    currentGroup: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        newGoal: this.get('store').createRecord('goal'),
        activeGoals: this.get('store').query('goal', {
          group_membership_id: [this.get('currentGroup.activeGroupMembershipId')],
          status: 'active'
        })
      });
    },
    afterModel: function afterModel(model) {
      this._setGroupMembership(model.newGoal);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('editedModel', 'model.newGoal');
    }
  });
});