define('ownersup-client/message/model', ['exports', 'ember-data', 'ownersup-client/commentable/model', 'ownersup-client/mixins/group-membershipable'], function (exports, _emberData, _model, _groupMembershipable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _model.default.extend(_groupMembershipable.default, {
    title: attr('string'),
    body: attr('string')
    // messageTypes,
    // messageType: attr('string', { defaultValue: messageTypes[0] })
  });
});