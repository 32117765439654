define('ownersup-client/comment/model', ['exports', 'ember-data', 'ownersup-client/mixins/timestampable', 'ownersup-client/mixins/authorizable'], function (exports, _emberData, _timestampable, _authorizable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_timestampable.default, _authorizable.default, {
    body: attr('string'),
    commentable: belongsTo('commentable', { polymorphic: true, async: true }),
    groupMembership: belongsTo('group-membership', { async: true }),
    isEditing: attr('boolean', { defaultValue: false })
  });
});