define('ownersup-client/account/billing/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      processStripeToken: function processStripeToken(token) {
        this.setProperties({
          token: token,
          askForPassword: true
        });
      }
    }
  });
});