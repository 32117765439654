define('ownersup-client/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ownersup-client/mixins/trackable-record'], function (exports, _unauthenticatedRouteMixin, _trackableRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Logger = Ember.Logger;
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _trackableRecord.default, {
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    _authenticationFailed: function _authenticationFailed(error) {
      Logger.debug('ApplicationRoute#actions#sessionAuthenticationFailed');

      this.get('flashMessages').danger(error.error);
      // Lord forgive me
      if (/confirm your email/.test(error.error)) {
        this.transitionTo('users.confirmation');
      }
    },


    actions: {
      login: function login() {
        var _this = this;

        this.controller.set('isSaving', true);

        var _controller$getProper = this.controller.getProperties('identification', 'password'),
            identification = _controller$getProper.identification,
            password = _controller$getProper.password;

        this.get('session').authenticate('authenticator:devise', identification, password).then(function () {
          _this.get('metrics').trackEvent({
            event: 'Logged In',
            email: identification
          });
        }).catch(function (error) {
          _this._authenticationFailed(error);
        }) // Tests fail unless I catch the failed promise here
        .finally(function () {
          _this.controller.set('isSaving', true);
        });
      }
    }
  });
});