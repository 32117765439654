define('ownersup-client/reports/new/route', ['exports', 'ownersup-client/mixins/group-membership-content-route', 'ownersup-client/mixins/reset-scroll', 'moment'], function (exports, _groupMembershipContentRoute, _resetScroll, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_groupMembershipContentRoute.default, _resetScroll.default, {
    session: Ember.inject.service('session'),
    currentGroup: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.get('store').query('report', {
        status: 'draft',
        group_membership_id: [this.get('currentGroup.activeGroupMembershipId')]
      }).then(function (drafts) {
        if (Ember.get(drafts, 'length') > 0) {
          return Ember.get(drafts, 'firstObject');
        } else {
          var createdAt = params.createdAt ? (0, _moment.default)(params.createdAt + ' 17').toDate() : (0, _moment.default)().toDate();
          var groupMembership = _this.get('currentGroup.activeGroupMembership');

          return _this.get('store').createRecord('report', { createdAt: createdAt, groupMembership: groupMembership });
        }
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        transition.data.model = this.controller.get('model');
        return true;
      }
    }
  });
});