define('ownersup-client/components/content-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['content-header'],
    attributeBindings: ['name:data-test-id'],
    tagName: 'header',
    name: 'content-header'
  });
});