define('ownersup-client/components/toggle-switch/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['toggle-switch'],
    classNameBindings: ['disabled'],
    attributeBindings: ['key:data-test-id'],
    disabled: false,
    model: false,

    value: Ember.computed('model', 'key', {
      get: function get() {
        var key = this.get('key');

        return this.get('model.' + key);
      },
      set: function set(setKey, value) {
        var key = this.get('key');
        this.get('model').set(key, value);

        return value;
      }
    }),

    valueChanged: Ember.observer('value', function () {
      var _this = this;

      this.toggleProperty('disabled');
      this.get('model').save().then(function () {
        _this.toggleProperty('disabled');
      });
    })
  });
});