define('ownersup-client/components/user-avatar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-avatar'],
    classNameBindings: ['avatarOnly', 'withoutCompany'],

    avatarOnly: false,
    withoutCompany: false,

    linkToUser: false,
    adminLink: false,
    userLink: Ember.computed('adminLink', function () {
      return this.get('adminlink') ? 'admin.users.user' : 'user';
    })
  });
});