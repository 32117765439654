define('ownersup-client/components/reports-summary/component', ['exports', 'ownersup-client/components/chart-base/component', 'ownersup-client/config/environment', 'ownersup-client/helpers/format-duration', 'ownersup-client/utils/report-stats', 'moment'], function (exports, _component, _environment, _formatDuration, _reportStats, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['reports-summary'],
    currentGroup: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.json = this.json || [{ Accomplishment: 0 }];

      this.colors = _environment.default.APP.categoryColors;
    },


    focusMessages: {
      'Sales': 'Great job!',
      'Marketing': 'Great job!',
      'Well Being': 'Great job!',
      'Retention': 'It\'s important to keep your existing customers happy. Great work. Don\'t forget about your pipeline though!',
      'Systems': 'Great work. Make sure to take some time to validate that your systems are increasing your time and revenue',
      'Partnerships': 'Make sure to take some time to validate that your partnerships are actually increasing your revenue',
      'Other': 'What\'s up? I thought you cared about your business'
    },

    dataFor: 7,
    sevenActive: Ember.computed.equal('dataFor', 7),
    thirtyActive: Ember.computed.equal('dataFor', 30),
    isEmpty: Ember.computed.lt('json.length', 1),
    categories: Ember.computed.oneWay('currentGroup.group.categories'),
    categoryNames: Ember.computed.mapBy('categories', 'name'),
    categoryChartKeys: Ember.computed.map('categoryNames', function (category) {
      return category + '.total_minutes';
    }),

    // summary: computed.oneWay('groupMembership.reportsSummary'),
    data: Ember.computed('json', 'categoryChartKeys', function () {
      var json = {
        json: this.get('json'),
        keys: {
          value: this.get('categoryChartKeys')
        },
        type: 'donut',
        colors: this.get('colors')
      };
      return json;
    }),

    legend: {
      show: false
    },

    tooltip: {
      format: {
        name: function name(_name) {
          return _name.replace('.total_minutes', '');
        },
        value: function value(_value) {
          return '' + (0, _formatDuration.formatDuration)(_value);
        }
      }
    },

    totalMinutes: Ember.computed('json.[]', function () {
      var json = this.get('json');
      return (0, _reportStats.totalMinutes)(json);
    }),

    legendData: Ember.computed('json.[]', 'categoryNames', 'totalMinutes', function () {
      var _getProperties = this.getProperties('json', 'categoryNames', 'totalMinutes'),
          json = _getProperties.json,
          categoryNames = _getProperties.categoryNames,
          totalMinutes = _getProperties.totalMinutes;

      return (0, _reportStats.categoryTotals)(json, categoryNames, totalMinutes);
    }),

    categorySort: ['minutes:desc'],
    sortedCategories: Ember.computed.sort('legendData', 'categorySort'),
    topCategory: Ember.computed.oneWay('sortedCategories.firstObject'),
    topCategoryHelpText: Ember.computed('topCategory', function () {
      return this.get('focusMessages')[this.get('topCategory.name')];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._getReportsSummary();
    },


    actions: {
      dataFor: function dataFor(days) {
        this.set('dataFor', days);
        this._getReportsSummary();
      }
    },

    _getReportsSummary: function _getReportsSummary() {
      var _this = this;

      var groupMembership = Ember.get(this, 'currentGroup.activeGroupMembership');
      var days = this.get('dataFor');
      var from = (0, _moment.default)().subtract(days, 'days').format('YYYY-MM-DD');

      if (!groupMembership || !groupMembership.customAction) {
        return;
      }

      groupMembership.customAction('stats/reports?from=' + from + '&groupBy=month').then(function (response) {
        _this.set('json', response.stats.report_stats);
      });
    }
  });
});