define('ownersup-client/mixins/flash-messageable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),
    _displayErrors: function _displayErrors(err) {
      var flash = Ember.get(this, 'flashMessages');
      var errors = err.errors || [{ detail: err.message }];

      errors.forEach(function (error) {
        flash.danger(error.detail);
      });
    },
    _displaySuccessMessage: function _displaySuccessMessage(message) {
      var flashMessages = Ember.get(this, 'flashMessages');
      flashMessages.clearMessages();
      flashMessages.success(message);
    }
  });
});