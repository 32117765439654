define('ownersup-client/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    if (testing) {
      this.setDefault({ duration: 10 });
    }

    this.transition(this.hasClass('tab-bar-menu-container'), this.toValue(true), this.use('toUp', options), this.reverse('toDown', options));

    this.transition(this.hasClass('transition-up-down'), this.toValue(true), this.use('toDown', options), this.reverse('toUp', options));

    this.transition(
    // this.withinRoute(function(routeName){ return routeName.startsWith('account'); }),
    this.hasClass('scope-bar-content-outlet'), this.use('fadeDown', { duration: 100 }));

    this.transition(this.hasClass('section-index-to-show'), this.use('fadeRight', { duration: 100 }));
  };

  var testing = Ember.testing;


  // import { target } from 'liquid-tether';

  var options = {
    duration: 150,
    easing: 'easeInOutQuint'
  };
});