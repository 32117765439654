define('ownersup-client/mixins/spinnerable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['isLoading:component-loading'],

    forceIsLoading: null,

    isLoading: Ember.computed('model.isLoading', 'forceIsLoading', function () {
      var forced = this.get('forceIsLoading');

      if (forced !== null) {
        return forced;
      }

      return !!this.get('model') && this.get('model.isLoading');
    }),

    start: function start() {
      this.set('forceIsLoading', true);
    },
    done: function done() {
      this.set('forceIsLoading', false);
    }
  });
});